<template>
  <div class="kpis-tree">
    <div @click="selectKPI(node)" class="label-wrapper py-2 border-bottom border-dark text-truncate" :class="depth ? 'label-wrapper-child' : 'label-wrapper-root'">
      <div :style="indent">
        <div :class="node.nodes && depth ? 'icon-pre-text' : 'd-none'">
          <svg class="bi" width="22" height="22" fill="currentColor">
            <use v-if="showChildren" xlink:href="@/assets/icons/icons.svg#dash"/>
            <use v-else xlink:href="@/assets/icons/icons.svg#plus"/>
          </svg>
        </div>
        <div class="icon-pre-text">
          <svg class="bi" width="22" height="22" fill="currentColor">
            <use v-if="node.elementType === 1" xlink:href="@/assets/icons/icons.svg#clipboard-data"/>
            <use v-else xlink:href="@/assets/icons/icons.svg#folder-fill"/>
          </svg>
          {{ node.label }}
        </div>
      </div>
    </div>
    <div v-if="showChildren || depth === 0">
      <KPIsTree v-for="node in node.nodes" :key="node.id" :node="node" :depth="depth + 1"></KPIsTree>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KPIsTree',
  props: ['node', 'depth'],
  data () {
    return {
      showChildren: false
    }
  },
  computed: {
    indent () {
      if (this.node.nodes && this.depth) {
        return { transform: `translate(${(this.depth - 1) * 22}px)` }
      } else {
        return { transform: `translate(${this.depth * 22}px)` }
      }
    }
  },
  methods: {
    async selectKPI (node) {
      this.$store.commit('SET_LOADING_STATUS', true)
      if (node.elementType === 1 && !this.showChildren) {
        await this.$store.dispatch('getKPI', { kpiId: node.id, kpiName: node.label, filter: { timedim: 3, timefrom: 2021, timeto: 2025 } })
      }
      this.showChildren = !this.showChildren
      this.$store.commit('SET_LOADING_STATUS', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.kpis-tree {
  .label-wrapper svg {
    margin-top: -2px;
    margin-left: 2px;
  }
  .label-wrapper-child {
    cursor: pointer;
  }
  .label-wrapper-child:hover {
    background-color: #212529;
    color: #f8f9fa;
  }
  .icon-pre-text {
    width: 22px;
    display: inline-block;
  }
}
</style>
