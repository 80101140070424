<template>
  <div class="row">
    <div v-if="Object.keys(selectedObjective).length !== 0" class="col-12">
      <div class="row mt-3">
        <div class="col">
          <a @click="openModal(selectedObjective.id, 'objective', 'edit')" class="btn btn-sm btn-outline-dark" href="#objectivesEditModal" data-bs-toggle="modal">&bull; Ziel bearbeiten</a>
          <a class="btn btn-sm btn-outline-primary ms-3" href="#">+ KPI hinzufügen</a>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <h3>{{ selectedObjective.name }}</h3>
          <div class="row">
            <div class="col-12">
              <span class="badge bg-transparent text-dark fs-6 fw-normal ps-0 pe-1">{{ selectedObjective.orgUnit }} &bull; {{ selectedObjective.inCharge }} &bull;
                {{ getObjectiveType(selectedObjective.type) }} &bull; {{ selectedObjective.startDate }} - {{ selectedObjective.endDate }} &bull;
              </span>
              <span v-for="(element, index) in smart" :key="'smart-' + index" :class="getSMARTStatus() ? 'text-bg-success' : 'text-bg-danger'" class="badge fs-6 fw-normal px-2 py-1 me-1">{{ element }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <div class="row">
            <div v-for="kpi in selectedObjective.kpis" :key="'kpi' + kpi.id" class="col-3">
              <div class="card">
                <div class="card-header fw-bold text-center text-truncate">
                  {{ kpi.name }}
                </div>
                <div class="card-body text-center py-1">
                  <p class="small mt-1 mb-0">Einheit: {{ kpi.unit }}</p>
                  <KPIPeriodSingleChart :key="'kpi-chart-single-' + kpi.id" :chartId="'chartSingle' + kpi.id" :period="kpi" class="kpi-chart" />
                </div>
                <div class="card-footer">
                  <div class="row">
                    <div class="col-6">
                      <router-link @click="selectKPI(kpi)" to="/kpi-structure" class="btn btn-sm btn-outline-primary">KPI ansehen</router-link>
                    </div>
                    <div class="col-4 offset-2 text-end my-auto">
                      <div class="progress position-relative border bg-dark border-dark">
                        <span class="progress-label position-absolute start-50 top-50 text-light fw-bold">{{ Math.round((kpi.forecastValue / kpi.goalValue) * 100) }}%</span>
                        <div class="progress-bar progress-bar-striped" :class="Math.round((kpi.forecastValue / kpi.goalValue) * 100) >= 100 ? 'bg-success' : 'bg-danger'" :style="'width: ' + Math.round((kpi.forecastValue / kpi.goalValue) * 100) + '%'" role="progressbar" :aria-valuenow="Math.round((kpi.forecastValue / kpi.goalValue) * 100)" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <div class="card">
            <div class="card-header fw-bold">Zieldetails</div>
            <div class="card-body">
              <ul class="nav nav-tabs nav-fill border-bottom" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link text-dark active" id="reviews-tab" data-bs-toggle="tab" data-bs-target="#reviews" type="button" role="tab" aria-controls="reviews" aria-selected="false">Reviews <span v-if="selectedObjective.reviews.length" class="badge bg-dark">{{ selectedObjective.reviews.length }}</span></button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link text-dark" id="measures-tab" data-bs-toggle="tab" data-bs-target="#measures" type="button" role="tab" aria-controls="measures" aria-selected="false">Maßnahmen <span v-if="selectedObjective.measures.length" class="badge bg-dark">{{ selectedObjective.measures.length }}</span></button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link text-dark" id="contribute-objectives-tab" data-bs-toggle="tab" data-bs-target="#contributeObjectives" type="button" role="tab" aria-controls="contributeObjectives" aria-selected="false">Beitragsziele <span v-if="selectedObjective.contributeObjectives.length" class="badge bg-dark">{{ selectedObjective.contributeObjectives.length }}</span></button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link text-dark" id="conflicts-tab" data-bs-toggle="tab" data-bs-target="#conflicts" type="button" role="tab" aria-controls="conflicts" aria-selected="false">Konflikte <span v-if="selectedObjective.conflicts.length" class="badge bg-dark">{{ selectedObjective.conflicts.length }}</span></button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link text-dark" id="trials-tab" data-bs-toggle="tab" data-bs-target="#trials" type="button" role="tab" aria-controls="trials" aria-selected="false">Lösungen <span v-if="selectedObjective.trials.length" class="badge bg-dark">{{ selectedObjective.trials.length }}</span></button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link text-dark" id="links-tab" data-bs-toggle="tab" data-bs-target="#links" type="button" role="tab" aria-controls="links" aria-selected="false">Links <span v-if="selectedObjective.links.length" class="badge bg-dark">{{ selectedObjective.links.length }}</span></button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link text-dark" id="notes-tab" data-bs-toggle="tab" data-bs-target="#notes" type="button" role="tab" aria-controls="notes" aria-selected="false">Notizen <span v-if="selectedObjective.notes.length" class="badge bg-dark">{{ selectedObjective.notes.length }}</span></button>
                </li>
              </ul>
              <div class="tab-content mt-2">
                <div class="tab-pane fade show active" id="reviews" role="tabpanel">
                  <div class="row my-3">
                    <div class="col">
                      <button @click="openModal(false, 'review', 'add')" type="button" class="btn btn-sm btn-outline-primary" data-bs-toggle="modal" data-bs-target="#objectivesEditModal">+ Hinzufügen</button>
                    </div>
                  </div>
                  <div v-if="selectedObjective.reviews.length">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Datum</th>
                          <th scope="col">Bewertung der Situation</th>
                          <th scope="col"></th>
                          <th scope="col">Erwartung</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="review in selectedObjective.reviews" :key="'review' + review.id">
                          <th scope="row">{{ review.dateTable }}</th>
                          <td class="w-50">{{ review.text }}</td>
                          <td>
                            <a href="#" data-bs-toggle="modal" data-bs-target="#objectivesReviewsKPIModal">KPI-Status</a>
                          </td>
                          <td>
                            <svg class="bi circle-review-status" width="24" height="24" fill="currentColor">
                              <use :class="review.status > 10 ? 'text-danger' : review.status > 5 ? 'text-warning' : 'text-success'" xlink:href="@/assets/icons/icons.svg#circle-fill"/>
                            </svg>
                          </td>
                          <td>
                            <div class="dropdown">
                              <a href="#" class="ms-2 text-dark" data-bs-toggle="dropdown">
                                <svg class="bi" width="28" height="28" fill="currentColor">
                                  <use xlink:href="@/assets/icons/icons.svg#three-dots"/>
                                </svg>
                              </a>
                              <ul class="dropdown-menu">
                                <li><a @click="openModal(review.id, 'review', 'edit')" class="dropdown-item" href="#objectivesEditModal" data-bs-toggle="modal">Bearbeiten</a></li>
                                <li><a @click="openModal(review.id, 'review', 'delete')" class="dropdown-item" href="#objectivesDeleteModal" data-bs-toggle="modal">Löschen</a></li>
                              </ul>
                            </div>
                          </td>
                          <div class="modal fade" id="objectivesReviewsKPIModal" tabindex="-1" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                              <div class="modal-content bg-primary">
                                <div class="modal-header">
                                  <h5 class="modal-title text-light">KPI-Status {{ review.dateTable }}</h5>
                                  <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body bg-light">
                                  <div class="row">
                                    <div v-for="(kpi, index) in selectedObjective.kpis" :key="'kpi-review' + kpi.id" class="col-12 my-2">
                                      <h5>{{ kpi.name }}</h5>
                                      <KPIPeriodSingleChart :key="'kpi-review-chart-single-' + kpi.id" :chartId="'chartSingle' + kpi.id" :period="kpi" class="kpi-chart" />
                                      <hr v-if="(index + 1) < selectedObjective.kpis.length" />
                                    </div>
                                  </div>
                                </div>
                                <div class="modal-footer bg-light">
                                  <button type="button" class="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Schließen</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else class="mt-3">
                    <h5>Dieses Ziel beinhaltet keine Reviews.</h5>
                  </div>
                </div>
                <div class="tab-pane fade" id="measures" role="tabpanel">
                  <div class="row my-3">
                    <div class="col">
                      <button @click="openModal(false, 'measure', 'add')" type="button" class="btn btn-sm btn-outline-primary" data-bs-toggle="modal" data-bs-target="#objectivesEditModal">+ Hinzufügen</button>
                    </div>
                  </div>
                  <div v-if="selectedObjective.measures.length">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Massnahme</th>
                          <th scope="col">Verantwortlich</th>
                          <th scope="col">Starttermin</th>
                          <th scope="col">Endtermin</th>
                          <th scope="col">Umsetzungsgrad</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="measure in selectedObjective.measures" :key="'measure' + measure.id">
                          <th scope="row">{{ measure.text }}</th>
                          <td>{{ measure.inChargeName }}</td>
                          <td>{{ measure.startDateTable }}</td>
                          <td>{{ measure.endDateTable }}</td>
                          <td>{{ measure.conversionDegree }}%</td>
                          <td>
                            <div class="dropdown">
                              <a href="#" class="ms-2 text-dark" data-bs-toggle="dropdown">
                                <svg class="bi" width="28" height="28" fill="currentColor">
                                  <use xlink:href="@/assets/icons/icons.svg#three-dots"/>
                                </svg>
                              </a>
                              <ul class="dropdown-menu">
                                <li><a @click="openModal(measure.id, 'measure', 'edit')" class="dropdown-item" href="#objectivesEditModal" data-bs-toggle="modal">Bearbeiten</a></li>
                                <li><a @click="openModal(measure.id, 'measure', 'delete')" class="dropdown-item" href="#objectivesDeleteModal" data-bs-toggle="modal">Löschen</a></li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else class="mt-3">
                    <h5>Dieses Ziel beinhaltet keine Massnahmen.</h5>
                  </div>
                </div>
                <div class="tab-pane fade" id="contributeObjectives" role="tabpanel">
                  <div v-if="selectedObjective.contributeObjectives.length">
                    <div class="row mt-3">
                      <div class="col-4">
                        <ul class="list-group">
                          <li class="list-group-item list-group-item-success fw-bold border-2">Wird erreicht</li>
                          <li v-for="(contributeObjective, index) in contributeObjectivesPos" :key="'contributeObjectivePos' + index" @click="selectObjective(contributeObjective.id)" class="list-group-item list-group-item-child" :class="index % 2 === 0 ? 'bg-custom-blue' : ''" aria-current="true">{{ contributeObjective.label }}</li>
                        </ul>
                      </div>
                      <div class="col-4">
                        <ul class="list-group">
                          <li class="list-group-item list-group-item-warning fw-bold border-2">Eventuell</li>
                          <li v-for="(contributeObjective, index) in contributeObjectivesNeu" :key="'contributeObjectiveNeu' + index" @click="selectObjective(contributeObjective.id)" class="list-group-item list-group-item-child" :class="index % 2 === 0 ? 'bg-custom-blue' : ''" aria-current="true">{{ contributeObjective.label }}</li>
                        </ul>
                      </div>
                      <div class="col-4">
                        <ul class="list-group">
                          <li class="list-group-item list-group-item-danger fw-bold border-2">Wird nicht erreicht</li>
                          <li v-for="(contributeObjective, index) in contributeObjectivesNeg" :key="'contributeObjectiveNeg' + index" @click="selectObjective(contributeObjective.id)" class="list-group-item list-group-item-child" :class="index % 2 === 0 ? 'bg-custom-blue' : ''" aria-current="true">{{ contributeObjective.label }}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div v-else class="mt-3">
                    <h5>Dieses Ziel beinhaltet keine Beitragsziele.</h5>
                  </div>
                </div>
                <div class="tab-pane fade" id="conflicts" role="tabpanel">
                  <div class="row my-3">
                    <div class="col">
                      <button @click="openModal(false, 'conflict', 'add')" type="button" class="btn btn-sm btn-outline-primary" data-bs-toggle="modal" data-bs-target="#objectivesEditModal">+ Hinzufügen</button>
                    </div>
                  </div>
                  <div v-if="selectedObjective.conflicts.length">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Bezeichnung</th>
                          <th scope="col">Status</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="conflict in selectedObjective.conflicts" :key="'conflict' + conflict.id">
                          <th scope="row">{{ conflict.text }}</th>
                          <td>
                            <svg class="bi" width="22" height="22" fill="currentColor">
                              <use :class="conflict.status === 1 ? 'text-success' : 'text-danger'" xlink:href="@/assets/icons/icons.svg#circle-fill"/>
                            </svg>
                          </td>
                          <td>
                            <div class="dropdown">
                              <a href="#" class="ms-2 text-dark" data-bs-toggle="dropdown">
                                <svg class="bi" width="28" height="28" fill="currentColor">
                                  <use xlink:href="@/assets/icons/icons.svg#three-dots"/>
                                </svg>
                              </a>
                              <ul class="dropdown-menu">
                                <li><a @click="openModal(conflict.id, 'conflict', 'edit')" class="dropdown-item" href="#objectivesEditModal" data-bs-toggle="modal">Bearbeiten</a></li>
                                <li><a @click="openModal(conflict.id, 'conflict', 'delete')" class="dropdown-item" href="#objectivesDeleteModal" data-bs-toggle="modal">Löschen</a></li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else class="mt-3">
                    <h5>Dieses Ziel beinhaltet keine Konflikte.</h5>
                  </div>
                </div>
                <div class="tab-pane fade" id="trials" role="tabpanel">
                  <div class="row my-3">
                    <div class="col">
                      <button @click="openModal(false, 'trial', 'add')" type="button" class="btn btn-sm btn-outline-primary" data-bs-toggle="modal" data-bs-target="#objectivesEditModal">+ Hinzufügen</button>
                    </div>
                  </div>
                  <div v-if="selectedObjective.trials.length">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Lösungsansatz</th>
                          <th scope="col">Bewertung</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="trial in selectedObjective.trials" :key="'trial' + trial.id">
                          <th scope="row">{{ trial.text }}</th>
                          <td v-if="trial.rating === 0">Noch nicht bewertet</td>
                          <td v-if="trial.rating === 1">Schwer umsetzbar</td>
                          <td v-if="trial.rating === 2">Gute Option</td>
                          <td v-if="trial.rating === 3">Verbesserungspriorität</td>
                          <td>
                            <div class="dropdown">
                              <a href="#" class="ms-2 text-dark" data-bs-toggle="dropdown">
                                <svg class="bi" width="28" height="28" fill="currentColor">
                                  <use xlink:href="@/assets/icons/icons.svg#three-dots"/>
                                </svg>
                              </a>
                              <ul class="dropdown-menu">
                                <li><a @click="openModal(trial.id, 'trial', 'edit')" class="dropdown-item" href="#objectivesEditModal" data-bs-toggle="modal">Bearbeiten</a></li>
                                <li><a @click="openModal(trial.id, 'trial', 'delete')" class="dropdown-item" href="#objectivesDeleteModal" data-bs-toggle="modal">Löschen</a></li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else class="mt-3">
                    <h5>Dieses Ziel beinhaltet keine Lösungsansätze.</h5>
                  </div>
                </div>
                <div class="tab-pane fade" id="links" role="tabpanel">
                  <div class="row my-3">
                    <div class="col">
                      <button @click="openModal(false, 'link', 'add')" type="button" class="btn btn-sm btn-outline-primary" data-bs-toggle="modal" data-bs-target="#objectivesEditModal">+ Hinzufügen</button>
                    </div>
                  </div>
                  <div v-if="selectedObjective.links.length">
                    <div v-for="link in selectedObjective.links" :key="'link' + link.id" class="row">
                      <div class="col-6">
                        <p class="mb-2"><a :href="link.path" target="_blank">{{ link.text }}</a></p>
                        <div class="dropdown">
                          <small>- {{ link.created }}</small>
                          <a href="#" class="ms-2 text-dark" data-bs-toggle="dropdown">
                            <svg class="bi" width="28" height="28" fill="currentColor">
                              <use xlink:href="@/assets/icons/icons.svg#three-dots"/>
                            </svg>
                          </a>
                          <ul class="dropdown-menu">
                            <li><a @click="openModal(link.id, 'link', 'edit')" class="dropdown-item" href="#objectivesEditModal" data-bs-toggle="modal">Bearbeiten</a></li>
                            <li><a @click="openModal(link.id, 'link', 'delete')" class="dropdown-item" href="#objectivesDeleteModal" data-bs-toggle="modal">Löschen</a></li>
                          </ul>
                        </div>
                        <hr>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <h5>Es wurden noch keine Links hinzugefügt.</h5>
                  </div>
                </div>
                <div class="tab-pane fade" id="notes" role="tabpanel">
                  <div class="row my-3">
                    <div class="col">
                      <button @click="openModal(false, 'note', 'add')" type="button" class="btn btn-sm btn-outline-primary" data-bs-toggle="modal" data-bs-target="#objectivesEditModal">+ Hinzufügen</button>
                    </div>
                  </div>
                  <div v-if="selectedObjective.notes.length">
                    <div v-for="note in selectedObjective.notes" :key="'note' + note.id" class="row">
                      <div class="col-6">
                        <p class="mb-2">{{ note.text }}</p>
                        <div class="dropdown">
                          <small>- {{ note.user }}, {{ note.created }}</small>
                          <a href="#" class="ms-2 text-dark" data-bs-toggle="dropdown">
                            <svg class="bi" width="28" height="28" fill="currentColor">
                              <use xlink:href="@/assets/icons/icons.svg#three-dots"/>
                            </svg>
                          </a>
                          <ul class="dropdown-menu">
                            <li><a @click="openModal(note.id, 'note', 'edit')" class="dropdown-item" href="#objectivesEditModal" data-bs-toggle="modal">Bearbeiten</a></li>
                            <li><a @click="openModal(note.id, 'note', 'delete')" class="dropdown-item" href="#objectivesDeleteModal" data-bs-toggle="modal">Löschen</a></li>
                          </ul>
                        </div>
                        <hr>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <h5>Es wurden noch keine Notizen hinzugefügt.</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ObjectivesEditModal></ObjectivesEditModal>
    <ObjectivesDeleteModal></ObjectivesDeleteModal>
  </div>
</template>

<script>
import ObjectivesEditModal from './ObjectivesEditModal.vue'
import ObjectivesDeleteModal from './ObjectivesDeleteModal.vue'
import KPIPeriodSingleChart from './charts/KPIPeriodSingle.vue'

export default {
  name: 'ObjectivesDetail',
  data () {
    return {
      smart: ['S', 'M', 'A', 'R', 'T'],
      objectiveModalElementId: false,
      objectiveModalElementType: '',
      objectiveModalMode: '',
      kpiChart: ''
    }
  },
  components: {
    ObjectivesEditModal,
    ObjectivesDeleteModal,
    KPIPeriodSingleChart
  },
  computed: {
    selectedObjective () {
      return this.$store.state.selectedObjective
    },
    contributeObjectivesPos () {
      return this.selectedObjective.contributeObjectives.filter(o => o.status < 6)
    },
    contributeObjectivesNeu () {
      return this.selectedObjective.contributeObjectives.filter(o => o.status > 5 && o.status < 11)
    },
    contributeObjectivesNeg () {
      return this.selectedObjective.contributeObjectives.filter(o => o.status > 10)
    }
  },
  methods: {
    openModal (id, elementType, mode) {
      if (id) {
        this.objectiveModalElementId = id
      }
      this.objectiveModalElementType = elementType
      this.objectiveModalMode = mode
    },
    selectKPI (kpi) {
      this.$store.commit('SET_LOADING_STATUS', true)
      this.$store.dispatch('getKPI', { kpiId: kpi.id, kpiName: kpi.name, filter: { timedim: 3, timefrom: 2021, timeto: 2025 } })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    async selectObjective (id) {
      this.$store.commit('SET_LOADING_STATUS', true)
      if (id > 0) {
        await this.$store.dispatch('getObjective', { objectiveId: id })
      }
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    getSMARTStatus () {
      return Math.random() < 0.5
    },
    getObjectiveType (type) {
      switch (type) {
        case 'O':
          return 'OKR'
        case 'Y':
          return 'Jahresziel'
        case 'B':
          return 'Durchbruchsziel'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.kpi-chart {
  height: 130px;
  padding: 5px;
}
.progress-label {
  transform: translate(-50%, -50%);
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background-color: #0d6efd;
  color: #f8f9fa !important;
}
th[scope='row'] {
  font-weight: normal;
}
th, td {
  padding-top: 11px !important;
}
.circle-review-status {
  margin-top: -1px;
  margin-left: -3px;
}
.list-group-item-child {
  cursor: pointer;
}
.list-group-item-child:hover {
  background-color: #212529;
  color: #f8f9fa;
}
</style>
