<template>
  <div class="container-fluid">
    <div class="row">
      <div id="sidebar-kpis" class="col-3 bg-custom-blue pb-5">
        <div class="row mt-3">
          <div class="col-12">
            <button class="btn btn-sm btn-outline-primary">+ KPI hinzufügen</button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <KPIsTree v-if="Object.keys(kpis).length" :node="kpis" :depth="0" class="mt-3"></KPIsTree>
            <div v-else class="text-center mt-5">
              <img src="@/assets/img/no_data.svg" class="img-fluid" width="120">
              <p class="h5 mt-3">Keine KPIs vorhanden.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-9 px-5">
        <div class="row mt-3">
          <div v-for="kpi in selectedKPIs" :key="kpi.id" class="col-6 mb-4">
            <div class="card kpi-detail mb-4 h-100">
              <div class="card-header fw-bold text-center text-truncate">
                <div class="row">
                  <div class="col-12 text-center">
                    {{ kpi.name }} <span v-if="kpi.unit">[{{ kpi.unit }}]</span>
                    <span @click=deselectKPI(kpi.id) role="button" class="btn-close small opacity-100 float-end"></span>
                  </div>
                </div>
              </div>
              <div class="card-body pt-2 pb-0">
                <div class="row mb-4">
                  <div class="col">
                    <label for="timedim" class="form-label small mb-0">Zeitraster:</label>
                    <select @change="updateKPIFromTo(kpi.id)" v-model="kpi.filter.timedim" id="timedim" class="form-select form-select-sm">
                      <option value="1">Monat</option>
                      <option value="2">Quartal</option>
                      <option value="3">Jahr</option>
                    </select>
                  </div>
                  <div class="col">
                    <label for="timefrom" class="form-label small mb-0">Von:</label>
                    <select v-model="kpi.filter.timefrom" id="timefrom" class="form-select form-select-sm">
                      <option v-for="element in getTimedata(kpi.filter.timedim)" :key="'timefrom' + element.value" :value="element.value">{{ element.name }}</option>
                    </select>
                  </div>
                  <div class="col">
                    <label for="timeto" class="form-label small mb-0">Bis:</label>
                    <select v-model="kpi.filter.timeto" id="timeto" class="form-select form-select-sm">
                      <option v-for="element in getTimedata(kpi.filter.timedim)" :key="'timeto' + element.value" :value="element.value">{{ element.name }}</option>
                    </select>
                  </div>
                  <div class="col mt-auto">
                    <button @click="filterKPI(kpi)" class="btn btn-sm btn-primary">Filtern</button>
                  </div>
                </div>
                <hr />
                <div v-if="kpi.periods">
                  <KPIPeriodMultiChart :key="kpiKey" :chartId="'chartMulti' + kpi.id" :periods="kpi.periods" class="kpi-chart" />
                  <div class="table-kpi-data mt-3">
                    <table class="table table-bordered table-striped mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Periode</th>
                          <th scope="col">Ziel</th>
                          <th scope="col">Ist</th>
                          <th scope="col">Forecast</th>
                          <th scope="col">Plan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="period in kpi.periods" :key="kpi.id + period">
                          <th scope="row">{{ period.period }}</th>
                          <td>{{ period.goalValue ? period.goalValue.toLocaleString('de-DE') : '-' }}</td>
                          <td>{{ period.currentValue ? period.currentValue.toLocaleString('de-DE') : '-' }}</td>
                          <td>{{ period.forecastValue ? period.forecastValue.toLocaleString('de-DE') : '-' }}</td>
                          <td>{{ period.planValue ? period.planValue.toLocaleString('de-DE') : '-' }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div v-else class="fs-5 pt-3 text-center">
                  Keine Daten vorhanden.
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 my-auto">
            <div class="kpi-detail-add text-center fs-4 rounded-4 mb-4 py-5">
              <div>KPI auswählen</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KPIsTree from '../../components/KPIsTree.vue'
import KPIPeriodMultiChart from '../../components/charts/KPIPeriodMulti.vue'

export default {
  name: 'KPIStructure',
  data () {
    return {
      kpis: {},
      kpiKeyInt: 0
    }
  },
  computed: {
    selectedKPIs () {
      return this.$store.state.selectedKPIs
    },
    kpiKey () {
      return this.kpiKeyInt + '-' + Math.random()
    }
  },
  components: {
    KPIsTree,
    KPIPeriodMultiChart
  },
  methods: {
    async fetchKPIsData () {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getKPIs', { structure: 't' }).then(response => {
        this.kpis = JSON.parse(response.data)
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    async filterKPI (kpi) {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getKPI', { kpiId: kpi.id, kpiName: kpi.name, filter: kpi.filter }).then(() => {
        this.kpiKeyInt++
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    deselectKPI (kpiId) {
      this.$store.commit('SET_LOADING_STATUS', true)
      this.$store.dispatch('deleteSelectedKPI', { kpiId: kpiId })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    updateKPIFromTo (kpiId) {
      var kpis = this.selectedKPIs
      var index = this.selectedKPIs.findIndex(kpi => kpi.id === kpiId)
      var kpi = this.selectedKPIs[index]
      if (kpi.filter.timedim === '1') {
        kpi.filter.timefrom = 202201
        kpi.filter.timeto = 202212
      } else if (kpi.filter.timedim === '2') {
        kpi.filter.timefrom = 20221
        kpi.filter.timeto = 20224
      } else {
        kpi.filter.timefrom = 2021
        kpi.filter.timeto = 2025
      }
      kpis[index] = kpi
      this.$store.commit('SET_SELECTED_KPIS', kpis)
    },
    getTimedata (timedim) {
      var data = []
      var i = 2021
      switch (Number(timedim)) {
        case 1:
          while (i <= 2025) {
            var iM = 1
            while (iM <= 12) {
              iM = (iM < 10) ? '0' + iM : iM
              data.push({ value: i.toString() + iM.toString(), name: i + '/' + iM })
              iM++
            }
            i++
          }
          break
        case 2:
          while (i <= 2025) {
            var iQ = 1
            while (iQ <= 4) {
              data.push({ value: i.toString() + iQ.toString(), name: i + 'Q' + iQ })
              iQ++
            }
            i++
          }
          break
        case 3:
          while (i <= 2025) {
            data.push({ value: i, name: i })
            i++
          }
      }
      return data
    }
  },
  async created () {
    this.fetchKPIsData()
  }
}
</script>

<style scoped>
.kpi-chart {
  max-height: 150px;
}
.table-kpi-data {
  max-height: 200px;
  overflow: scroll;
}
.kpi-detail-add {
  border-style: dashed !important;
}
</style>
