<template>
  <div>
    <Header></Header>
    <Alert></Alert>
    <Loading></Loading>
    <main :class="isLoading ? 'bg-light opacity-25' : ''">
      <router-view/>
    </main>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Alert from './components/Alert.vue'
import Loading from './components/Loading.vue'

export default {
  name: 'App',
  components: {
    Header,
    Alert,
    Loading
  },
  computed: {
    isLoading () {
      return this.$store.state.loadingStatus
    }
  }
}
</script>

<style lang="scss">
a {
  text-decoration: none !important;
}
.bg-custom-blue {
  background-color: rgb(0 87 177 / 11%);
}
#sidebar-objectives, #sidebar-manage-org-units, #sidebar-kpis {
  min-height: 100vh !important;
}
</style>
