import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import * as microsoftTeams from '@microsoft/teams-js'

store.dispatch('initialiseApp')

createApp(App).use(store).use(router).mount('#app')

if (window.location.href.includes('context=msteams')) {
  microsoftTeams.app.initialize()
  microsoftTeams.app.getContext((context) => {
    const userId = document.getElementById('user')
    userId.innerHTML = context.userPrincipalName
  })
}
