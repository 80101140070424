<template>
  <Bar
    :chart-id="chartId"
    :chart-options="chartOptions"
    :chart-data="chartData"
  />
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Tooltip, BarElement, PointElement, CategoryScale, LinearScale, Legend } from 'chart.js'
import pattern from 'patternomaly'

ChartJS.register(BarElement, Tooltip, PointElement, CategoryScale, LinearScale, Legend)

export default {
  name: 'KPIPeriodMultiChart',
  components: { Bar },
  props: ['chartId', 'periods'],
  data () {
    return {
      chartData: {
        labels: this.periods.map(period => period.period),
        datasets: [
          {
            label: 'Ziel',
            data: this.periods.map(period => period.goalValue),
            backgroundColor: '#28436c',
            barPercentage: 0.6,
            maxBarThickness: 50
          },
          {
            label: 'Ist',
            data: this.periods.map(period => period.currentValue),
            backgroundColor: '#44a1b7',
            barPercentage: 0.6,
            maxBarThickness: 50
          },
          {
            label: 'Forecast',
            data: this.periods.map(period => period.forecastValue),
            backgroundColor: pattern.draw('diagonal-right-left', '#00000040'),
            barPercentage: 0.6,
            maxBarThickness: 50
          },
          {
            label: 'Plan',
            data: this.periods.map(period => period.planValue),
            backgroundColor: 'grey',
            barPercentage: 0.6,
            maxBarThickness: 50
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  }
}
</script>
