<template>
  <div class="container-fluid">
    <button v-if="objectivesSidebarView === 'hide'" @click="toggleObjectivesSidebarView('show')" class="btn btn-sm btn-primary rounded-0 rounded-end position-absolute start-0 mt-3">
      <svg class="bi" width="21" height="21" fill="currentColor">
        <use xlink:href="@/assets/icons/icons.svg#caret-right-fill"/>
      </svg>
    </button>
    <div class="row" :class="objectivesSidebarView === 'show' ? '' : 'justify-content-center'">
      <div v-if="objectivesSidebarView === 'show'" id="sidebar-objectives" class="col-3 bg-custom-blue pb-5">
        <div class="row mt-3">
          <div class="col-12">
            <button v-if="objectivesContentView === 'detail'" @click="toggleObjectivesContentView('add')" class="btn btn-sm btn-outline-primary">+ Ziel hinzufügen</button>
            <button v-if="objectivesContentView === 'add' && objectivesSidebarView === 'show'" @click="toggleObjectivesSidebarView('hide')" class="btn btn-sm btn-outline-primary">
              &lt; Ausblenden
            </button>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-5">
            <div class="dropdown">
              <button class="btn btn-sm btn-outline-dark dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span v-if="selectedYear == '2021'">01.01.2021 - 31.12.2021</span>
                <span v-else>01.01.2022 - 31.12.2022</span>
              </button>
              <div class="dropdown-menu border-secondary p-3">
                <div class="row">
                  <div class="col-12">
                    <button @click="selectYear('2022')" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">Aktuelles Jahr</button>
                    <button @click="selectYear('2021')" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">Letztes Jahr</button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <button class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">Q1</button>
                    <button class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">Q2</button>
                    <button class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">Q3</button>
                    <button class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">Q4</button>
                  </div>
                </div>
                <hr class="dropdown-divider">
                <div class="row">
                  <div class="col-6">
                    <label for="selectDateStart" class="form-label">Von:</label>
                    <input type="date" class="form-control p-0" id="selectDateStart">
                  </div>
                  <div class="col-6">
                    <label for="selectDateEnd" class="form-label">Bis:</label>
                    <input type="date" class="form-control p-0" id="selectDateEnd">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-7 text-end">
            <span class="me-2">Ansicht: </span>
            <div class="btn-group btn-group-objectives-view" role="group">
              <button @click="toggleObjectivesTreeView('org')" type="button" class="btn btn-sm btn-outline-primary" :class="objectivesTreeView === 'org' ? 'active' : ''">Org</button>
              <button @click="toggleObjectivesTreeView('obj')" type="button" class="btn btn-sm btn-outline-primary" :class="objectivesTreeView === 'obj' ? 'active' : ''">Ziel</button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ObjectivesTree v-if="Object.keys(objectives).length" :node="objectives" :depth="0" :view="objectivesTreeView" class="mt-3"></ObjectivesTree>
            <div v-else class="text-center mt-5">
              <img src="@/assets/img/no_data.svg" class="img-fluid" width="120">
              <p class="h5 mt-3">Keine Ziele vorhanden.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-9 px-5">
        <ObjectivesDetail v-if="objectivesContentView === 'detail'"></ObjectivesDetail>
        <ObjectivesAdd v-if="objectivesContentView === 'add'"></ObjectivesAdd>
      </div>
    </div>
  </div>
</template>

<script>
import ObjectivesTree from '../../components/ObjectivesTree.vue'
import ObjectivesDetail from '../../components/ObjectivesDetail.vue'
import ObjectivesAdd from '../../components/ObjectivesAdd.vue'

export default {
  name: 'Objectives',
  data () {
    return {
      selectedYear: new Date().getFullYear(),
      objectivesTreeView: 'org',
      objectives: {},
      objectivesSidebarView: 'show'
    }
  },
  computed: {
    objectivesContentView () {
      return this.$store.state.objectivesContentView
    }
  },
  components: {
    ObjectivesTree,
    ObjectivesDetail,
    ObjectivesAdd
  },
  methods: {
    async fetchObjectivesData (selectedYear, objectivesTreeView) {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getObjectives', { selectedYear, objectivesTreeView }).then(response => {
        this.objectives = JSON.parse(response.data)
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    selectYear (year) {
      this.selectedYear = year
      this.fetchObjectivesData(this.selectedYear, this.objectivesTreeView)
    },
    toggleObjectivesSidebarView (objectivesSidebarView) {
      this.objectivesSidebarView = objectivesSidebarView
    },
    toggleObjectivesContentView (objectivesContentView) {
      this.$store.commit('SET_OBJECTIVES_CONTENT_VIEW', objectivesContentView)
    },
    toggleObjectivesTreeView (objectivesTreeView) {
      this.objectivesTreeView = objectivesTreeView
      this.fetchObjectivesData(this.selectedYear, this.objectivesTreeView)
    }
  },
  async created () {
    this.fetchObjectivesData(this.selectedYear, this.objectivesTreeView)
    this.$store.dispatch('getObjective', { objectiveId: 23 })
  }
}
</script>

<style scoped>
.dropdown-toggle::before {
  display: inline-block;
  margin-right: .255em;
  vertical-align: .2em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}
.dropdown-toggle:after {
  display: none;
}
.dropdown-menu {
  min-width: 330px;
}
</style>
