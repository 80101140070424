<template>
  <Bar
    :chart-id="chartId"
    :chart-options="chartOptions"
    :chart-data="chartData"
  />
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Tooltip, BarElement, PointElement, CategoryScale, LinearScale } from 'chart.js'
import pattern from 'patternomaly'

ChartJS.register(BarElement, Tooltip, PointElement, CategoryScale, LinearScale)

export default {
  name: 'KPIPeriodSingleChart',
  components: { Bar },
  props: ['chartId', 'period'],
  data () {
    return {
      chartData: {
        labels: ['Vorjahr', 'Ziel', 'Ist', 'Forecast'],
        datasets: [{
          data: [this.period.lastYearValue, this.period.goalValue, this.period.currentValue, this.period.forecastValue],
          backgroundColor: ['#44a1b7', '#28436c', '#44a1b7', pattern.draw('diagonal-right-left', '#00000040')]
        }]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        }
      }
    }
  }
}
</script>
