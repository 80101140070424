<template>
  <header class="bg-primary border-bottom border-light">
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <router-link to="/" class="navbar-brand text-light">
          <svg id="navbar-logo" class="bi pb-1 me-2" width="30" height="30" fill="currentColor">
            <use xlink:href="@/assets/icons/icons.svg#nut-fill"/>
          </svg>
          <span v-if="isAuthenticated">Adventure Bikes</span>
          <span v-else>focus</span>
        </router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul v-if="isAuthenticated" class="navbar-nav mx-auto mb-2 mb-lg-0">
            <li class="nav-item dropdown px-lg-2">
              <a class="nav-link dropdown text-light pe-0" href="#" id="navbarDropdownMonitoring" role="button" data-bs-toggle="dropdown" aria-expanded="false">Monitoring</a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownMonitoring">
                <li><router-link to="/kpi-structure" class="dropdown-item">KPI-Struktur</router-link></li>
                <li><button class="dropdown-item disabled">KPI-Cockpit</button></li>
                <li><button class="dropdown-item disabled">Abweichungsanalyse</button></li>
                <li><button class="dropdown-item disabled">KPI-Daten</button></li>
                <li><hr class="dropdown-divider"></li>
                <li><button class="dropdown-item disabled">Customizing</button></li>
              </ul>
            </li>
            <li class="nav-item dropdown px-lg-2">
              <a class="nav-link dropdown text-light pe-0" href="#" id="navbarDropdownObjectives" role="button" data-bs-toggle="dropdown" aria-expanded="false">Ziele</a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownObjectives">
                <li><router-link to="/objectives" class="dropdown-item">Zielstruktur</router-link></li>
                <li><button class="dropdown-item disabled">Zielübersicht</button></li>
                <li><router-link to="/demo/x-matrix" class="dropdown-item">X-Matrix</router-link></li>
                <li><hr class="dropdown-divider"></li>
                <li><button class="dropdown-item disabled">Customizing</button></li>
              </ul>
            </li>
            <li class="nav-item dropdown px-lg-2">
              <a class="nav-link dropdown text-light pe-0" href="#" id="navbarDropdownProjects" role="button" data-bs-toggle="dropdown" aria-expanded="false">Projekte</a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownProjects">
                <li><router-link to="/projects" class="dropdown-item">Projektstruktur</router-link></li>
                <li><button class="dropdown-item disabled">Multiprojektübersicht</button></li>
                <li><button class="dropdown-item disabled">Maßnahmenübersicht</button></li>
                <li><hr class="dropdown-divider"></li>
                <li><button class="dropdown-item disabled">Customizing</button></li>
              </ul>
            </li>
          </ul>
          <ul v-else-if="currentRoute !== 'Login'" class="navbar-nav mx-auto mb-2 mb-lg-0">
            <li class="nav-item px-lg-2"><a href="#features" class="nav-link text-light pe-0">Features</a></li>
            <li class="nav-item px-lg-2"><a href="#team" class="nav-link text-light pe-0">Team</a></li>
            <li class="nav-item px-lg-2"><a href="#contact" class="nav-link text-light pe-0">Kontakt</a></li>
          </ul>
          <ul v-if="isAuthenticated" class="navbar-nav mb-2 mb-lg-0">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown text-light pe-2" href="#" id="navbarDropdownManage" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <svg class="bi" width="23" height="23" fill="currentColor">
                  <use xlink:href="@/assets/icons/icons.svg#gear-fill"/>
                </svg>
              </a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownManage">
                <li><router-link to="/manage/org-units" class="dropdown-item">Org-Struktur</router-link></li>
                <li><button class="dropdown-item disabled">Mitarbeiter</button></li>
                <li><hr class="dropdown-divider"></li>
                <li><button class="dropdown-item disabled">Benutzerverwaltung</button></li>
                <li><hr class="dropdown-divider"></li>
                <li><button class="dropdown-item disabled">Systemsettings</button></li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown text-light pe-0" href="#" id="navbarDropdownUser" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <svg class="bi" width="28" height="28" fill="currentColor">
                  <use xlink:href="@/assets/icons/icons.svg#person-fill"/>
                </svg>
              </a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownUser">
                <li><button class="dropdown-item disabled">Hilfe</button></li>
                <li><button class="dropdown-item disabled">Profil</button></li>
                <li><hr class="dropdown-divider"></li>
                <li><button class="dropdown-item disabled">Sprache</button></li>
                <li><hr class="dropdown-divider"></li>
                <li><button @click="logout" class="dropdown-item">Logout</button></li>
              </ul>
            </li>
          </ul>
          <ul v-else class="navbar-nav mb-2 mb-lg-0" :class="currentRoute === 'Login' ? 'ms-auto' : ''">
            <li v-if="currentRoute !== 'Login'" class="nav-item"><router-link to="/login" class="nav-link text-light pe-0 mt-3 mt-lg-0">Login</router-link></li>
            <li v-else class="nav-item"><router-link to="/" class="nav-link text-light pe-0">Startseite</router-link></li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { useRoute } from 'vue-router'

export default {
  name: 'Header',
  computed: {
    isAuthenticated () {
      return this.$store.state.username
    },
    currentRoute () {
      return useRoute().name
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
    }
  }
}
</script>
