<template>
  <div class="objectives-tree">
    <div @click="selectObjective(node)" class="label-wrapper py-2 border-bottom border-dark text-truncate" :class="depth ? 'label-wrapper-child' : 'label-wrapper-root'">
      <div :style="indent">
        <div :class="node.nodes && depth ? 'icon-pre-text' : 'd-none'">
          <svg class="bi" width="22" height="22" fill="currentColor">
            <use v-if="showChildren" xlink:href="@/assets/icons/icons.svg#dash"/>
            <use v-else xlink:href="@/assets/icons/icons.svg#plus"/>
          </svg>
        </div>
        <div class="icon-pre-text">
          <span v-if="node.type" class="obj-type rounded-2 fw-normal text-light text-center small me-1">{{ node.type }}</span>
          <svg class="bi" width="22" height="22" fill="currentColor">
            <use v-if="node.elementType === 'org'" xlink:href="@/assets/icons/icons.svg#org-unit"/>
            <use v-else :class="node.status > 10 ? 'text-danger' : node.status > 5 ? 'text-warning' : 'text-success'" xlink:href="@/assets/icons/icons.svg#circle-fill"/>
          </svg>
          {{ node.label }}
        </div>
      </div>
    </div>
    <div v-if="showChildren || depth === 0">
      <ObjectivesTree v-for="node in node.nodes" :key="node.id" :node="node" :depth="depth + 1" :view="view"></ObjectivesTree>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ObjectivesTree',
  props: ['node', 'depth', 'view'],
  data () {
    return {
      showChildren: false
    }
  },
  computed: {
    indent () {
      if (this.node.nodes && this.depth) {
        return { transform: `translate(${(this.depth - 1) * 22}px)` }
      } else {
        return { transform: `translate(${this.depth * 22}px)` }
      }
    }
  },
  methods: {
    async selectObjective (node) {
      this.$store.commit('SET_LOADING_STATUS', true)
      this.showChildren = !this.showChildren
      if (node.id > 0 && node.elementType === 'obj') {
        await this.$store.dispatch('getObjective', { objectiveId: node.id }).then(() => {
          this.$store.commit('SET_OBJECTIVES_CONTENT_VIEW', 'detail')
        })
      }
      this.$store.commit('SET_LOADING_STATUS', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.objectives-tree {
  .label-wrapper svg {
    margin-top: -2px;
    margin-left: 2px;
  }
  .label-wrapper-child {
    cursor: pointer;
  }
  .label-wrapper-child:hover {
    background-color: #212529;
    color: #f8f9fa;
  }
  .icon-pre-text {
    width: 22px;
    display: inline-block;
  }
  .icon-pre-text .obj-type {
    display: inline-block;
    min-width: 20px;
    background-color: #999;
    margin-left: 3px;
  }
}
</style>
