<template>
  <div class="row">
    <div class="col-12">
      <div class="row mt-3">
        <div class="col">
          <button @click="toggleObjectivesContentView('detail')" type="button" class="btn btn-sm btn-outline-dark">&lt; Zurück</button>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3>Ziel hinzufügen</h3>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-12">
          <span v-for="(value, key) in smart" :key="'smart-' + key" :class="value ? 'text-bg-success' : 'text-bg-danger'" class="badge fs-5 fw-normal px-2 py-1 me-2">{{ key }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="progress">
            <div class="progress-bar" role="progressbar" :style="progress" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <div class="card w-100">
            <div class="card-body">
              <div class="row mb-5">
                <div class="col-4">
                  <label for="orgUnitId" class="form-label">
                    Organisationseinheit
                  </label>
                  <select v-model="newObjective.orgUnitId" id="orgUnitId" class="form-select">
                    <option v-for="orgUnit in orgUnits" :key="orgUnit.id" :value="orgUnit.id">{{ orgUnit.name }}</option>
                  </select>
                </div>
                <div class="col-5 offset-2">
                  <label for="parentId" class="form-label">Übergeordnetes Ziel</label>
                  <select v-model="newObjective.parentId" id="parentId" class="form-select">
                    <option v-for="parent in parentObjectives" :key="parent.id" :value="parent.id">{{ parent.name }}</option>
                  </select>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-5">
                  <div class="row">
                    <div class="col-12">
                      <label for="name" class="form-label">Zieltitel</label>
                      <input v-model="newObjective.name" id="name" class="form-control" placeholder="Der Umsatz ist gesteigert">
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12">
                      <input v-model="newObjective.ambitious" id="checkNameAmbitious" :disabled="newObjective.name.trim() ? false : true" class="form-check-input" type="checkbox" value="">
                      <label class="form-check-label ms-1" for="checkNameAmbitious">
                        Ambitioniert
                      </label>
                      <input v-model="newObjective.realistic" id="checkNameRealistic" :disabled="newObjective.name.trim() ? false : true" class="form-check-input ms-3" type="checkbox" value="">
                      <label class="form-check-label ms-1" for="checkNameRealistic">
                        Realistisch
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-4 offset-1">
                  <label for="inChargeId" class="form-label">Zielnehmer</label>
                  <select v-model="newObjective.inChargeId" id="inChargeId" class="form-select">
                    <option v-for="employee in employees" :key="employee.id" :value="employee.id">{{ employee.name }}</option>
                  </select>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-5">
                  <label for="description" class="form-label">Zielbeschreibung</label>
                  <textarea v-model="newObjective.description" id="description" class="form-control" rows="3"></textarea>
                </div>
                <div class="col-4 offset-1">
                  <label for="inChargeId" class="form-label">Zieltyp</label>
                  <select v-model="newObjective.type" id="type" class="form-select">
                    <option value="o">OKR</option>
                    <option value="y">Jahresziel</option>
                    <option value="b">Durchbruchsziel</option>
                  </select>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-3">
                  <label for="startDate" class="form-label">Startdatum</label>
                  <input v-model="newObjective.startDate" type="date" id="startDate" class="form-control" placeholder="01.01.2022">
                </div>
                <div class="col-3">
                  <label for="endDate" class="form-label">Enddatum</label>
                  <input v-model="newObjective.endDate" type="date" id="endDate" class="form-control" placeholder="31.12.2022">
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <label for="kpis" class="form-label">KPIs</label>
                  <div class="input-group">
                    <select @change="addKPI()" v-model="kpiSelected" id="kpis" class="form-select">
                      <option :value="{ id: 0, name: 'Auswählen...' }">{{ kpiSelected.name }}</option>
                      <option v-for="kpi in kpis" :key="kpi.id" :value="kpi">{{ kpi.name }}</option>
                    </select>
                    <button class="btn btn-outline-primary" type="button">+ Erstellen</button>
                  </div>
                </div>
              </div>
              <div class="row selected-kpis">
                <div class="col-12 mt-3">
                  <div v-for="selectedKPI in newObjective.kpis" :key="'selected-kpi' + selectedKPI.id" class="input-group mb-3">
                    <input type="text" class="form-control" placeholder="Zielwert">
                    <span class="input-group-text">
                      {{ selectedKPI.name }}
                      <span v-if="selectedKPI.unit !== '-'">&nbsp;[{{ selectedKPI.unit }}]</span>
                    </span>
                    <router-link @click="selectKPI(selectedKPI)" to="/kpi-structure" class="btn btn-outline-primary">KPI ansehen</router-link>
                    <button @click="deleteKPI()" class="btn btn-outline-danger" type="button">
                      <svg class="bi" width="18" height="18" fill="currentColor">
                        <use xlink:href="@/assets/icons/icons.svg#trash-fill"/>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-3">
        <div class="col text-end">
          <button class="btn btn-primary ms-3" :class="progress.width === '100%' ? '' : 'disabled'">Ziel hinzufügen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ObjectivesAdd',
  data () {
    return {
      orgUnits: [],
      employees: [],
      checkNameAmbitious: false,
      checkNameRealistic: false,
      kpis: [],
      kpiSelected: { id: 0, name: 'Auswählen...' }
    }
  },
  computed: {
    newObjective () {
      return this.$store.state.newObjective
    },
    parentObjectives () {
      return this.$store.state.parentObjectives
    },
    progress () {
      var progress = 0
      Object.entries(this.newObjective).forEach(entry => {
        if ((!Array.isArray(entry[1]) && entry[1]) || (Array.isArray(entry[1]) && entry[1].length)) {
          if ((entry[0] === 'name' && entry[1].trim()) || entry[0] !== 'name') {
            progress = progress + (100 / 11)
          }
        }
      })
      return { width: Math.round(progress) + '%' }
    },
    smart () {
      var smart = { S: false, M: false, A: false, R: false, T: false }
      Object.keys(smart).forEach(key => {
        switch (key) {
          case 'S':
            if (this.newObjective.name.trim() && this.newObjective.parentId && this.newObjective.orgUnitId && this.newObjective.inChargeId) {
              smart[key] = true
            }
            break
          case 'M':
            if (this.newObjective.kpis.length) {
              smart[key] = true
            }
            break
          case 'A':
            if (this.newObjective.name.trim() && this.newObjective.ambitious) {
              smart[key] = true
            }
            break
          case 'R':
            if (this.newObjective.name.trim() && this.newObjective.realistic) {
              smart[key] = true
            }
            break
          case 'T':
            if (this.newObjective.startDate !== '' && this.newObjective.endDate !== '') {
              smart[key] = true
            }
            break
        }
      })
      return smart
    }
  },
  methods: {
    addKPI () {
      this.$store.dispatch('addObjectiveKPI', { kpi: this.kpiSelected })
      this.kpiSelected = { id: 0, name: 'Auswählen...' }
    },
    selectKPI (kpi) {
      this.$store.commit('SET_LOADING_STATUS', true)
      this.$store.dispatch('getKPI', { kpiId: kpi.id, kpiName: kpi.name })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    deleteKPI () {
      this.$store.dispatch('deleteObjectiveKPI', { kpiId: this.kpiSelected.id })
    },
    async saveObjective () {
      this.$store.commit('SET_LOADING_STATUS', true)
      try {
        var newObjectiveId = 23
        var valid = true
        if (this.newObjective.name === '') {
          valid = false
        }
        if (valid === true) {
          await this.$store.dispatch('addObjective', { newObjective: this.newObjective }).then((response) => {
            newObjectiveId = response.newObjectiveId
          })
        }
        this.$parent.fetchObjectivesData(this.$parent.selectedYear, this.$parent.objectivesTreeView)
        await this.$store.dispatch('getObjective', { objectiveId: newObjectiveId })
        this.$store.dispatch('updateObjectivesContentView', { objectivesContentView: 'detail' })
      } finally {
        this.$store.commit('SET_LOADING_STATUS', false)
      }
    },
    toggleObjectivesContentView (objectivesContentView) {
      this.$store.commit('SET_OBJECTIVES_CONTENT_VIEW', objectivesContentView)
    }
  },
  created () {
    this.$store.dispatch('getOrgUnits').then((response) => {
      this.orgUnits = JSON.parse(response.data)
    })
    this.$store.dispatch('getEmployees').then((response) => {
      this.employees = JSON.parse(response.data)
    })
    this.$store.dispatch('getKPIs', { structure: 'l' }).then((response) => {
      this.kpis = JSON.parse(response.data)
    })
  }
}
</script>

<style scoped>
.page-link:active, .page-link:focus {
  box-shadow: none;
  background-color: #fff;
}
@keyframes progress-pusher {
  0%   { opacity: 0 }
  33%  { opacity: 33 }
  66%  { opacity: 66 }
  100% { opacity: 0 }
}
#progress-pusher-1, #progress-pusher-2, #progress-pusher-3 {
  animation: progress-pusher;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
.selected-kpis input {
  max-width: 10%;
}
</style>
