<template>
  <div class="container-fluid">
    <div class="row justify-content-center mt-5">
      <div class="col-9">
        <div class="accordion" id="accordionProjects">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingDev">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDev" aria-expanded="true" aria-controls="collapseDev">
                Entwicklungsprojekte
              </button>
            </h2>
            <div id="collapseDev" class="accordion-collapse collapse show" aria-labelledby="headingDev" data-bs-parent="#accordionProjects">
              <div class="accordion-body">
                <div class="row">
                  <div class="col-3">
                    <div class="card h-100">
                      <div class="card-body">
                        <h5 class="card-title">Fully 2022</h5>
                        <h6 class="card-subtitle mb-2 text-muted">Mountainbike</h6>
                        <div class="progress position-relative border bg-dark border-dark lh-sm my-3">
                          <span class="progress-label position-absolute start-50 text-light fw-bold">76%</span>
                          <div class="progress-bar progress-bar-striped bg-success" style="width: 76%;" role="progressbar" :aria-valuenow="76" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <p class="card-text">Entwicklung eines neuen full-suspension Mountainbikes</p>
                      </div>
                      <div class="card-footer">
                        <div class="row">
                          <div class="col-6">
                            <a href="#" class="btn btn-sm btn-outline-primary">Projekt ansehen</a>
                          </div>
                          <div class="col-6 text-end">
                            <div class="dropdown">
                              <a href="#" class="ms-2 text-dark" data-bs-toggle="dropdown">
                                <svg class="bi" width="28" height="28" fill="currentColor">
                                  <use xlink:href="@/assets/icons/icons.svg#three-dots"/>
                                </svg>
                              </a>
                              <ul class="dropdown-menu">
                                <li><a href="#" class="dropdown-item">Bearbeiten</a></li>
                                <li><a href="#" class="dropdown-item">Löschen</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="card h-100">
                      <div class="card-body">
                        <h5 class="card-title">Gravelpro 3.0</h5>
                        <h6 class="card-subtitle mb-2 text-muted">Gravelbike</h6>
                        <div class="progress position-relative border bg-dark border-dark lh-sm my-3">
                          <span class="progress-label position-absolute start-50 text-light fw-bold">34%</span>
                          <div class="progress-bar progress-bar-striped bg-danger" style="width: 34%;" role="progressbar" :aria-valuenow="34" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <p class="card-text">Fortführung der Gravelpro-Serie</p>
                      </div>
                      <div class="card-footer">
                        <div class="row">
                          <div class="col-6">
                            <a href="#" class="btn btn-sm btn-outline-primary">Projekt ansehen</a>
                          </div>
                          <div class="col-6 text-end">
                            <div class="dropdown">
                              <a href="#" class="ms-2 text-dark" data-bs-toggle="dropdown">
                                <svg class="bi" width="28" height="28" fill="currentColor">
                                  <use xlink:href="@/assets/icons/icons.svg#three-dots"/>
                                </svg>
                              </a>
                              <ul class="dropdown-menu">
                                <li><a href="#" class="dropdown-item">Bearbeiten</a></li>
                                <li><a href="#" class="dropdown-item">Löschen</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOrg">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOrg" aria-expanded="false" aria-controls="collapseOrg">
                Organisationsprojekte
              </button>
            </h2>
            <div id="collapseOrg" class="accordion-collapse collapse" aria-labelledby="headingOrg" data-bs-parent="#accordionProjects">
              <div class="accordion-body"></div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingIT">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseIT" aria-expanded="false" aria-controls="collapseIT">
                IT-Projekte
              </button>
            </h2>
            <div id="collapseIT" class="accordion-collapse collapse" aria-labelledby="headingIT" data-bs-parent="#accordionProjects">
              <div class="accordion-body"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Projects'
}
</script>
