<template>
  <div v-if="hasAlerts" class="toast-container position-absolute top-0 end-0 mt-5 py-3 px-2">
    <div v-for="(alert, index) in alerts" :key="'alertToast' + index" class="toast show">
      <div class="toast-header" :class="alert.category === 'Error' ? 'bg-danger' : 'bg-primary'">
        <strong class="text-white me-auto">{{ alert.category }}</strong>
        <button @click="deleteAlert(index)" type="button" class="btn-close btn-close-white" aria-label="Close"></button>
      </div>
      <div class="toast-body bg-light rounded-2">
        {{ alert.message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  data () {
    return {
      alerts: this.$store.state.alerts
    }
  },
  computed: {
    hasAlerts () {
      return this.$store.state.alerts.length
    }
  },
  methods: {
    deleteAlert (index) {
      this.$store.commit('DELETE_ALERT', index)
    }
  }
}
</script>

<style>
.toast-container {
  z-index: 999 !important;
}
</style>
