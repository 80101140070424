<template>
  <div class="container">
    <div class="row my-5">
      <div class="col-12">
        <div id="x-matrix" class="small table-responsive">
          <table class="table table-bordered">
            <tbody>
              <tr v-for="(topRow, index) in topRows" :key="'topRow' + index" class="align-middle">
                <td v-for="(cell, index) in topRow.cells" :key="'topCell' + index" :class="cell == 1 ? 'text-center' : cell != 0 ? 'bg-matrix-grey' : ''">
                  <span v-if="cell && cell == 1" class="fs-2 my-auto">&bull;</span>
                  <span v-if="cell && cell != 1">{{ cell }}</span>
                </td>
              </tr>
              <tr class="align-middle bg-matrix-grey">
                <td v-for="(cell, index) in centerRows.left" :key="'centerRowLeft' + index" class="text-rotate">{{ cell }}</td>
                <td class="recross fw-bold position-relative p-0">
                  <div class="container">
                    <div class="row justify-content-center">
                      <div class="col-4 my-auto">
                        <div>Jahresziele 2022</div>
                      </div>
                      <div class="col-4 text-center bg-light py-5">
                        <div class="fs-6">X-Matrix <br /> Adventure Bikes</div>
                      </div>
                      <div class="col-4 text-end my-auto">
                        <div>Erfolgsindikatoren 2022</div>
                      </div>
                    </div>
                  </div>
                  <div class="position-absolute top-0 start-50 translate-middle pt-5">Verbesserungsprioritäten</div>
                  <div class="position-absolute top-100 start-50 translate-middle pb-5">Durchbruchsziele 2025</div>
                </td>
                <td v-for="(cell, index) in centerRows.right" :key="'centerRowRight' + index" class="text-rotate">{{ cell }}</td>
              </tr>
              <tr v-for="(bottomRow, index) in bottomRows" :key="'bottomRow' + index" class="align-middle">
                <td v-for="(cell, index) in bottomRow.cells" :key="'bottomCell' + index" :class="cell == 1 ? 'text-center' : cell != 0 ? 'bg-matrix-grey' : ''">
                  <span v-if="cell && cell == 1" class="fs-2 my-auto">&bull;</span>
                  <span v-if="cell && cell != 1">{{ cell }}</span>
                </td>
                <td colspan="8"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DemoXMatrix',
  data () {
    return {
      topRows: [
        { cells: [0, 0, 1, 'Umsatzziele mit Ländern vereinbaren', 1, 0, 0, 0, 0, 0, 0] },
        { cells: [0, 0, 1, 'Detailliert weltweite Umsatzplanung erstellen', 0, 1, 0, 0, 0, 0, 0] },
        { cells: [0, 1, 0, 'Ratio Potenziale weltweit aufzeigen', 0, 0, 1, 0, 0, 0, 0] },
        { cells: [0, 1, 0, 'Senkung der Kosten mit Fokus auf Schwund und Vernichtung ', 0, 0, 0, 1, 0, 0, 0] },
        { cells: [0, 1, 0, 'Steigerung der Produktivität an allen Montagestandorten', 0, 0, 0, 0, 1, 0, 0] },
        { cells: [1, 0, 0, 'Fehlerportfolios und Maßnahmentracking etablieren', 0, 0, 0, 0, 0, 1, 1] }
      ],
      centerRows: {
        left: [
          'Die Qualität weltweit ist verbessert. Die Reklamationskosten sind von 0,46 % auf 0,4 % reduziert',
          'Der Ertrag weltweit ist gesteigert. Die Ebit-Marge ist von 4,8 % auf 7,5 % gestiegen',
          'Der Umsatz weltweit ist von 69,5 Mio € auf 85 Mio € gesteigert'
        ],
        right: [
          'Der Umsatzanteil mit neuen Produkten ist auf 50 % gesteigert',
          'Die geplanten Umsatzpotenziale werden zu 100 % ausgeschöpft',
          'Die Investitionsquote von 2,5 % wird nicht überschritten.',
          'Reduktion der Verschrottungskosten um 15 %',
          'Steigerung der Produktivität je Mitarbeiterstunde um 3%',
          'Die Checklisten zur Fehlerbehebung werden termingerecht abgearbeitet',
          'Die FPY Quote ist um 10% gesteigert'
        ]
      },
      bottomRows: [
        { cells: [0, 0, 1, 'Der Umsatz weltweit ist von 69,5 Mio € auf 120 Mio € gesteigert'] },
        { cells: [0, 1, 0, 'Der Ertrag weltweit ist gesteigert. Die Ebit-Marge ist von 4,8 % auf 10 % gestiegen'] },
        { cells: [1, 0, 0, 'Die Qualität weltweit ist verbessert. Die Reklamationskosten sind von 0,46 % auf 0,3 % reduziert'] }
      ]
    }
  }
}
</script>

<style scoped>
.bg-matrix-grey {
  background-color: lightgray;
}
#x-matrix .text-rotate {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}
#x-matrix .recross {
  background-image:
    linear-gradient(to top left,
      rgba(0,0,0,0) 0%,
      rgba(0,0,0,0) calc(50% - 0.8px),
      rgba(2,21,41,1) 50%,
      rgba(0,0,0,0) calc(50% + 0.8px),
      rgba(0,0,0,0) 100%),
    linear-gradient(to top right,
      rgba(0,0,0,0) 0%,
      rgba(0,0,0,0) calc(50% - 0.8px),
      rgba(2,21,41,1) 50%,
      rgba(0,0,0,0) calc(50% + 0.8px),
      rgba(0,0,0,0) 100%);
  background-color: lightblue;
}
#x-matrix td {
  padding: 0.5rem 1rem 0.5rem 1rem !important;
}
#x-matrix td[class="text-rotate"] {
  line-height: 22px;
}
</style>
