<template>
  <div id="hero">
    <div class="container">
      <div class="row pt-3 pt-lg-0">
        <div class="col-12 col-lg-6 text-center text-lg-start my-auto ps-md-5 ps-xl-3">
          <h1 class="display-5 display-lg-1">
            <span class="bg-dark text-light px-3 rounded-3">focus</span><br />Management<br />Tool
          </h1>
        </div>
        <div class="d-none d-lg-block col-lg-4 offset-lg-2 my-auto pe-md-5 pe-xl-3 py-5">
          <img src="@/assets/img/management.svg" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
  <div id="features" class="mb-3">
    <div class="container pb-5">
      <div class="row justify-content-center text-center mt-3 mt-lg-5 pb-0 pb-lg-3">
        <div class="col-12 col-lg-4 px-5 mb-3 mb-lg-0">
          <div class="features-top py-5">
            <div class="h4">Ziele und OKRs</div>
            <svg class="bi" width="50" height="50">
              <use xlink:href="@/assets/icons/icons.svg#bullseye"/>
            </svg>
          </div>
        </div>
        <div class="col-12 col-lg-4 col-4 px-5 mb-3 mb-lg-0">
          <div class="features-top py-5">
            <div class="h4">KPI-Monitoring</div>
            <svg class="bi" width="50" height="50">
              <use xlink:href="@/assets/icons/icons.svg#bar-chart-line-fill"/>
            </svg>
          </div>
        </div>
        <div class="col-12 col-lg-4 col-4 px-5">
          <div class="features-top py-5">
            <div class="h4">Projekte</div>
            <svg class="bi" width="50" height="50">
              <use xlink:href="@/assets/icons/icons.svg#kanban-fill"/>
            </svg>
          </div>
        </div>
      </div>
      <div class="row justify-content-center my-0 my-lg-5 pt-5 pb-3 pb-lg-5">
        <div class="col-12 col-lg-5 my-auto">
          <h2>Ziele und OKRs</h2>
          <ul>
            <li>Kaskadierung der Ziele entlang der Organisationsstruktur</li>
            <li>Verbindung zum Monitoring durch Zuordnung von Kennzahlen</li>
            <li>Review-Systematik zur regelmäßigen Analyse der Zielerreichung</li>
            <li>Visualisierung des Zielstatus und des Umsetzungsgrades</li>
          </ul>
        </div>
        <div class="col-12 col-lg-5 offset-lg-2 my-auto">
          <div class="card text-center shadow">
            <div class="card-header">
              <h5 class="text-primary mb-0 py-2">Der Umsatz in Spanien ist gesteigert</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <ul class="nav nav-tabs nav-fill border-bottom mb-3" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button class="nav-link text-dark active" id="reviews-tab" data-bs-toggle="tab" data-bs-target="#reviews" type="button" role="tab" aria-controls="reviews" aria-selected="false">Reviews <span class="badge bg-dark">3</span></button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link text-dark" id="measures-tab" data-bs-toggle="tab" data-bs-target="#measures" type="button" role="tab" aria-controls="measures" aria-selected="false">Maßnahmen <span class="badge bg-dark">2</span></button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link text-dark" id="conflicts-tab" data-bs-toggle="tab" data-bs-target="#conflicts" type="button" role="tab" aria-controls="conflicts" aria-selected="false">Konflikte <span class="badge bg-dark">3</span></button>
                    </li>
                  </ul>
                  <div class="tab-content">
                    <div class="tab-pane fade show active" id="reviews" role="tabpanel">
                      Unterstützung von strukturierten Ziele-Reviews, Abschätzung der voraussichtlichen Zielwerte, Dokumentation der Reviews und Nachverfolgbarkeit der Zielerreichung
                    </div>
                    <div class="tab-pane fade" id="measures" role="tabpanel">
                      Dokumentation von Maßnahmen zur Ermöglichung der Zielerreichung von Zielen der jeweiligen Organisationseinheit, Zuordnung von Verantwortlichen aus der Organisation
                    </div>
                    <div class="tab-pane fade" id="conflicts" role="tabpanel">
                      Kennzeichnung von Zielkonflikten beim jeweiligen Ziel, Beschreibung der Konflikte, Adressierung des Konflikts an den jeweiligen Konfliktpartner
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer"></div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center my-0 my-lg-5 pt-5 pb-3 pb-lg-5">
        <div class="col-12 col-lg-5 order-2 order-lg-1 my-auto">
          <div class="card text-center shadow">
            <div class="card-header">
              <h5 class="text-primary mb-0 py-2">Umsatz (Mio. Euro)</h5>
            </div>
            <div class="card-body text-center">
              <div class="row justify-content-center">
                <div class="col-9">
                  <div class="progress position-relative border bg-dark border-dark lh-sm">
                    <span class="progress-label position-absolute start-50 text-light fw-bold">36%</span>
                    <div class="progress-bar progress-bar-striped bg-primary" style="width: 36%;" role="progressbar" :aria-valuenow="36" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
              <KPIPeriodSingleChart :chartId="'chartSingleLanding'" :period="{ lastYearValue: 12, goalValue: 14, currentValue: 5, forecastValue: 16 }" class="kpi-chart" />
            </div>
            <div class="card-footer"></div>
          </div>
        </div>
        <div class="col-12 col-lg-5 offset-lg-2 order-1 order-lg-2 my-auto">
          <h2>KPI-Monitoring</h2>
          <ul>
            <li>Abbildung und Visualisierung strategisch bedeutsamer Kennzahlen</li>
            <li>Datenreihen für Ziel-, Plan-, Ist-, Forecast- und Benchmark-Werte</li>
            <li>Ampelsystematik zur Visualisierung kritischer Kennzahlen</li>
            <li>Abweichungs- und Veränderungsanalysen</li>
          </ul>
        </div>
      </div>
      <div class="row justify-content-center my-0 my-lg-5 pt-5 pb-3 pb-lg-5">
        <div class="col-12 col-lg-5 my-auto">
          <h2>Projekte</h2>
          <ul>
            <li>Planung, Steuerung und Analyse strategisch bedeutsamer Projekte</li>
            <li>Termin (Meilenstein)-, Budget und Maßnahmenmanagement</li>
            <li>Review-Systematik zur regelmäßigen Analyse des Projektstatus</li>
            <li>Analyse des Projektgeschehens (Kosten, Termine, Qualität)</li>
          </ul>
        </div>
        <div class="col-12 col-lg-5 offset-lg-2 my-auto">
          <div class="card text-center shadow">
            <div class="card-header">
              <h5 class="card-title text-primary">Alpine 2022</h5>
              <h6 class="card-subtitle text-muted">Mountainbike</h6>
            </div>
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-9">
                  <div class="progress position-relative border bg-dark border-dark lh-sm mb-3">
                    <span class="progress-label position-absolute start-50 text-light fw-bold">76%</span>
                    <div class="progress-bar progress-bar-striped bg-success" style="width: 76%;" role="progressbar" :aria-valuenow="76" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
              <p class="card-text">Fortführung der Alpine-Serie mit der Entwicklung eines neuen full-suspension Mountainbikes.</p>
              <p class="mb-1">
                <span class="badge bg-primary mx-2">Dauer: 8 Monate</span>
                <span class="badge bg-success mx-2">Status: OK</span>
                <span class="badge bg-warning text-dark mx-2 mt-2 mt-lg-0">Checks: 4</span>
              </p>
            </div>
            <div class="card-footer"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="team" class="pb-3">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center mt-5 pt-5">
          <h2 class="h1">Das Team hinter focus</h2>
        </div>
      </div>
      <div class="row justify-content-center my-5 py-5">
        <div class="col-12 col-lg-12 my-auto">
          <div class="card">
            <div class="card-body">
              <div class="row p-3 p-lg-5">
                <div class="col-12 col-xl-5 my-auto">
                  <h4>Unsere Vision</h4>
                  <p class="fs-5"><i>"Maßgeschneidertes, agiles & web-basiertes Ziele- und KPI-System für das Management im Mittelstand"</i></p>
                  <p>Mit langjähriger Erfahrung im Ziele-Management entwickelt das focus-Team ein digitales Werkzeug für die Manager von heute und unterstützt von der Definition des ersten Ziels bis zur Etablierung der Review-Struktur ganzheitlich mit einem Produkt direkt aus der Cloud.</p>
                </div>
                <div class="col-12 col-xl-7">
                  <div class="row">
                    <div class="col-12 col-lg-4 offset-lg-2 text-center mb-5 mb-lg-0">
                      <div class="row">
                        <div class="col-12">
                          <img src="@/assets/img/bernd_respondek.jpeg" class="img-fluid rounded-circle" width="100" alt="Bernd Respondek">
                        </div>
                        <div class="col-12">
                          <h6 class="mt-2 mb-1">Bernd Respondek</h6>
                          <p class="badge bg-primary mb-2">Managementberatung</p>
                          <p>
                            <a href="https://www.linkedin.com/in/bernd-respondek-262b12122/" target="_blank">
                              <svg class="bi" width="28" height="28">
                                <use xlink:href="@/assets/icons/icons.svg#linkedin"/>
                              </svg>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-4 text-center mb-5 mb-lg-0">
                      <div class="row">
                        <div class="col-12">
                          <img src="@/assets/img/thomas_gehring.jpeg" class="img-fluid rounded-circle" width="100" alt="Thomas Gehring">
                        </div>
                        <div class="col-12">
                          <h6 class="mt-2 mb-1">Thomas Gehring</h6>
                          <p class="badge bg-primary mb-2">Zielemanagement</p>
                          <p>
                            <a href="https://www.linkedin.com/in/thomas-gehring-6a67ba199/" target="_blank">
                              <svg class="bi" width="28" height="28">
                                <use xlink:href="@/assets/icons/icons.svg#linkedin"/>
                              </svg>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-4 text-center mb-5 mb-lg-0">
                      <div class="row">
                        <div class="col-12">
                          <img src="@/assets/img/simon_michels.png" class="img-fluid rounded-circle" width="100" alt="Simon Michels">
                        </div>
                        <div class="col-12">
                          <h6 class="mt-2 mb-1">Simon Michels</h6>
                          <p class="badge bg-primary mb-2">Produktentwicklung</p>
                          <p>
                            <a href="https://www.linkedin.com/in/simi901/" target="_blank">
                              <svg class="bi" width="28" height="28">
                                <use xlink:href="@/assets/icons/icons.svg#linkedin"/>
                              </svg>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-4 text-center mb-5 mb-lg-0">
                      <div class="row">
                        <div class="col-12">
                          <img src="@/assets/img/helmut_meissner.png" class="img-fluid rounded-circle" width="100" alt="Helmut Meissner">
                        </div>
                        <div class="col-12">
                          <h6 class="mt-2 mb-1">Helmut Meissner</h6>
                          <p class="badge bg-primary mb-2">Datenintegration</p>
                          <p>
                            <a href="https://www.linkedin.com/in/helmut-meissner-ba1058154/" target="_blank">
                              <svg class="bi" width="28" height="28">
                                <use xlink:href="@/assets/icons/icons.svg#linkedin"/>
                              </svg>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-4 text-center">
                      <div class="row">
                        <div class="col-12">
                          <img src="@/assets/img/matthias_singler.jpeg" class="img-fluid rounded-circle" width="100" alt="Matthias Singler">
                        </div>
                        <div class="col-12">
                          <h6 class="mt-2 mb-1">Matthias Singler</h6>
                          <p class="badge bg-primary mb-2">IT-Betrieb & -Distribution</p>
                          <p>
                            <a href="https://www.linkedin.com/in/matthiassingler/" target="_blank">
                              <svg class="bi" width="28" height="28">
                                <use xlink:href="@/assets/icons/icons.svg#linkedin"/>
                              </svg>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="contact">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center mt-5">
          <h2>Sie haben Fragen oder möchten focus testen?</h2>
        </div>
      </div>
      <div class="row justify-content-center mt-3 pb-5">
        <div class="col-12 text-center">
          <a href="mailto:matthias.singler@m2digitals.com" class="btn btn-lg btn-primary px-5">Kontaktieren</a>
        </div>
      </div>
    </div>
  </div>
  <footer class="w-100 bg-primary text-light mt-auto py-3">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <span>&copy; {{ new Date().getFullYear() }}
            <a href="/" class="text-light">focus</a>
          </span> &bull;
          <a href="#" class="text-light">Impressum</a> &bull;
          <a href="#" class="text-light">Datenschutz</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import KPIPeriodSingleChart from '../components/charts/KPIPeriodSingle.vue'

export default {
  name: 'Landing',
  components: {
    KPIPeriodSingleChart
  }
}
</script>

<style scoped>
#hero, #team, .features-top {
  background-color: #0d6efd29;
}
#hero {
  clip-path: polygon(0 0, 100% 0, 100% 50%, 90% 100%, 10% 100%, 0% 50%);
}
.features-top {
  -webkit-clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
  clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background-color: #0d6efd;
  color: #f8f9fa !important;
}
.kpi-chart {
  max-height: 150px;
  padding: 5px;
}
#team {
  clip-path: polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0% 50%);
}
</style>
