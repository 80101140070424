<template>
  <div class="objective-modal">
    <div class="modal fade" id="objectivesDeleteModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-primary">
          <div class="modal-header">
            <h5 v-if="elementType" class="modal-title text-light">{{ vocabulary[elementType].text }} löschen</h5>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body bg-light">
            <div class="my-3">
              <p v-if="elementType">Möchten Sie {{ vocabulary[elementType].det }} {{ vocabulary[elementType].text }} wirklich löschen?</p>
            </div>
          </div>
          <div class="modal-footer bg-light">
            <button type="button" class="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Schließen</button>
            <button @click="deleteElement()" type="button" class="btn btn-sm btn-danger" data-bs-dismiss="modal">Löschen</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ObjectivesDeleteModal',
  data () {
    return {
      vocabulary: {
        objective: {
          test: 'Ziel',
          det: 'das'
        },
        review: {
          text: 'Review',
          det: 'das'
        },
        measure: {
          text: 'Massnahme',
          det: 'die'
        },
        conflict: {
          text: 'Konflikt',
          det: 'den'
        },
        trial: {
          text: 'Lösungsansatz',
          det: 'den'
        },
        link: {
          text: 'Link',
          det: 'den'
        },
        note: {
          text: 'Notiz',
          det: 'die'
        }
      }
    }
  },
  computed: {
    selectedObjective () {
      return this.$store.state.selectedObjective
    },
    elementType () {
      return this.$parent.objectiveModalElementType
    }
  },
  methods: {
    async deleteElement () {
      try {
        this.$store.commit('SET_LOADING_STATUS', true)
        const elementId = this.$parent.objectiveModalElementId
        await this.$store.dispatch('deleteObjectiveElement', { objectiveId: this.selectedObjective.id, elementType: (this.elementType + 's'), elementId: elementId }).then(() => {
          this.$parent.$parent.fetchObjectivesData(this.$parent.$parent.selectedYear, this.$parent.$parent.objectivesTreeView)
          this.$store.dispatch('getObjective', { objectiveId: this.selectedObjective.id })
        })
      } finally {
        this.$store.commit('SET_LOADING_STATUS', false)
      }
    }
  }
}
</script>
