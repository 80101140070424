<template>
  <div class="objective-modal">
    <div class="modal fade" id="objectivesEditModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-primary">
          <div class="modal-header">
            <h5 class="modal-title text-light">{{ vocabulary[elementType] }} {{ vocabulary[mode] }}</h5>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body bg-light">
            <div v-if="elementType === 'objective'" class="form-link">
              <div class="mb-3">
                <label for="objectiveOrgUnitId" class="form-label">Organisationseinheit</label>
                <select v-model="element.orgUnitId" id="objectiveOrgUnitId" class="form-select">
                  <option v-for="orgUnit in orgUnits" :key="orgUnit.id" :value="orgUnit.id">{{ orgUnit.name }}</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="objectiveParentId" class="form-label">Übergeordnetes Ziel</label>
                <select v-model="element.parentId" id="objectiveParentId" class="form-select">
                  <option v-for="parent in parentObjectives" :key="parent.id" :value="parent.id">{{ parent.name }}</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="objectiveInChargeId" class="form-label">Zielnehmer</label>
                <select v-model="element.inChargeId" id="objectiveInChargeId" class="form-select">
                  <option v-for="employee in employees" :key="employee.id" :value="employee.id">{{ employee.name }}</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="objectiveName" class="form-label">Zieltitel</label>
                <input v-model="element.name" id="objectiveName" class="form-control" placeholder="Der Umsatz ist gesteigert">
              </div>
            </div>
            <div v-if="elementType === 'review'" class="form-link">
              <div class="mb-3">
                <label for="reviewDate" class="form-label">Datum</label>
                <input v-model="element.date" type="date" id="reviewDate" class="form-control">
              </div>
              <div class="mb-3">
                <label for="reviewText" class="form-label">Bemerkung</label>
                <textarea v-model="element.text" id="reviewText" class="form-control" rows="3"></textarea>
              </div>
              <div class="mb-3">
                <label for="reviewForecastValue" class="form-label">Erwartung</label>
                <select class="form-select" id="reviewForecastValue">
                  <option value="0">Wird erreicht</option>
                  <option value="1">Eventuell</option>
                  <option value="2">Wird nicht erreicht</option>
                </select>
              </div>
            </div>
            <div v-if="elementType === 'measure'" class="form-link">
              <div class="mb-3">
                <label for="measureText" class="form-label">Bezeichnung</label>
                <textarea v-model="element.text" id="measureText" class="form-control" rows="3"></textarea>
              </div>
              <div class="mb-3">
                <label for="measureInCharge" class="form-label">Verantwortlich</label>
                <select v-model="element.inChargeId" id="measureInChargeId" class="form-select">
                  <option v-for="employee in employees" :key="employee.id" :value="employee.id">{{ employee.name }}</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="measureStartDate" class="form-label">Start</label>
                <input v-model="element.startDate" type="date" id="measureStartDate" class="form-control">
              </div>
              <div class="mb-3">
                <label for="measureEndDate" class="form-label">Ende</label>
                <input v-model="element.endDate" type="date" id="measureEndDate" class="form-control">
              </div>
              <div class="mb-3">
                <label for="measureConversionDegree" class="form-label">Umsetzungsgrad</label>
                <input v-model="element.conversionDegree" type="number" id="measureConversionDegree" class="form-control">
              </div>
            </div>
            <div v-if="elementType === 'conflict'" class="form-link">
              <div class="mb-3">
                <label for="conflictText" class="form-label">Text</label>
                <input v-model="element.text" class="form-control">
              </div>
              <div class="mb-3">
                <input v-model="element.status" class="form-check-input" type="checkbox" id="conflictStatus">
                <label for="conflictStatus" class="form-check-label ms-2">Status</label>
              </div>
            </div>
            <div v-if="elementType === 'trial'" class="form-link">
              <div class="mb-3">
                <label for="trialText" class="form-label">Text</label>
                <input v-model="element.text" class="form-control">
              </div>
              <div class="mb-3">
                <label for="trialRating" class="form-label">Bewertung</label>
                <select v-model="element.rating" class="form-select" id="trialRating">
                  <option value="0">Noch nicht bewertet</option>
                  <option value="1">Schwer umsetzbar</option>
                  <option value="2">Gute Option</option>
                  <option value="3">Verbesserungspriorität</option>
                </select>
              </div>
            </div>
            <div v-if="elementType === 'link'" class="form-link">
              <div class="mb-3">
                <label for="linkText" class="form-label">Text</label>
                <input v-model="element.text" class="form-control">
              </div>
              <div class="mb-3">
                <label for="linkPath" class="form-label">Link</label>
                <input v-model="element.path" class="form-control">
              </div>
            </div>
            <div v-if="elementType === 'note'" class="form-note">
              <div class="mb-3">
                <label for="noteText" class="form-label">Text</label>
                <textarea v-model="element.text" class="form-control" rows="3"></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer bg-light justify-content-between">
            <div>
              <button v-if="elementType === 'objective'" type="button" class="btn btn-sm btn-outline-danger" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#objectiveDeleteModal">Löschen</button>
            </div>
            <div>
              <button type="button" class="btn btn-sm btn-outline-secondary me-2" data-bs-dismiss="modal">Schließen</button>
              <button type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal">Speichern</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="objectiveDeleteModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-primary">
          <div class="modal-header">
            <h5 class="modal-title text-light">Ziel löschen</h5>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body bg-light">
            <div class="my-3">
              <p v-if="Object.keys(selectedObjective).length > 0 && selectedObjective.contributeObjectives.length">
                Das Ziel kann nicht gelöscht werden, da es Beitragsziele enthält. Bitte zunächst die Beitragsziele löschen.
              </p>
              <div v-else>
                <p>Möchten Sie das Ziel und dessen Inhalte wirklich löschen?</p>
                <ul v-if="Object.keys(selectedObjective).length > 0">
                  <li>Reviews: {{ selectedObjective.reviews.length }}</li>
                  <li>Maßnahmen: {{ selectedObjective.measures.length }}</li>
                  <li>Konflikte: {{ selectedObjective.conflicts.length }}</li>
                  <li>Lösungsansätze: {{ selectedObjective.trials.length }}</li>
                  <li>Links: {{ selectedObjective.links.length }}</li>
                  <li>Notizen: {{ selectedObjective.notes.length }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer bg-light">
            <button type="button" class="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Schließen</button>
            <button type="button" class="btn btn-sm btn-danger disabled" data-bs-dismiss="modal">Löschen</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ObjectivesEditModal',
  data () {
    return {
      element: {},
      vocabulary: {
        objective: 'Ziel',
        review: 'Review',
        measure: 'Massnahme',
        conflict: 'Konflikt',
        trial: 'Lösungsansatz',
        note: 'Notiz',
        link: 'Link',
        add: 'hinzufügen',
        edit: 'bearbeiten'
      },
      employees: [],
      orgUnits: []
    }
  },
  computed: {
    selectedObjective () {
      return this.$store.state.selectedObjective
    },
    parentObjectives () {
      return this.$store.state.parentObjectives
    },
    elementId () {
      return this.$parent.objectiveModalElementId
    },
    elementType () {
      return this.$parent.objectiveModalElementType
    },
    mode () {
      return this.$parent.objectiveModalMode
    }
  },
  methods: {
    initElementData () {
      this.element = {
        id: '',
        orgUnitId: '',
        parentId: '',
        name: '',
        measurand: '',
        measurementUnit: '',
        lastYearValue: '',
        goalValue: '',
        text: '',
        date: this.getDateToday(),
        startDate: this.getDateToday(),
        endDate: this.getDateToday(),
        currentValue: 0,
        forecastValue: 0,
        inChargeId: 1,
        conversionDegree: 0,
        status: false,
        rating: 0,
        path: ''
      }
    },
    async saveElement () {
      this.$store.commit('SET_LOADING_STATUS', true)
      try {
        var valid = true
        if (this.element.text === '' && this.element.name === '') {
          valid = false
        }
        if (valid === true) {
          if (this.mode === 'add') {
            await this.$store.dispatch('addObjectiveElement', { objectiveId: this.selectedObjective.id, elementType: (this.elementType + 's'), element: this.element })
          } else if (this.mode === 'edit') {
            if (this.elementType === 'objective') {
              await this.$store.dispatch('updateObjective', { objective: this.element })
            } else {
              await this.$store.dispatch('updateObjectiveElement', { objectiveId: this.selectedObjective.id, elementType: (this.elementType + 's'), element: this.element })
            }
          }
        }
        this.$parent.$parent.fetchObjectivesData(this.$parent.$parent.selectedYear, this.$parent.$parent.objectivesTreeView)
        await this.$store.dispatch('getObjective', { objectiveId: this.selectedObjective.id })
      } finally {
        this.$store.commit('SET_LOADING_STATUS', false)
      }
    },
    updateModalContent () {
      if (this.elementType === 'objective') {
        this.element = this.selectedObjective
      } else {
        const elementTypePlural = this.elementType + 's'
        const element = this.selectedObjective[elementTypePlural].find(element => {
          return element.id === this.$parent.objectiveModalElementId
        })
        Object.assign(this.element, element)
        if (this.element.status === 1) {
          this.element.status = true
        } else {
          this.element.status = false
        }
      }
    },
    getDateToday () {
      const today = new Date()
      const year = today.getFullYear()
      let month = today.getMonth() + 1
      let day = today.getDate()
      if (day < 10) day = '0' + day
      if (month < 10) month = '0' + month
      return year + '-' + month + '-' + day
    }
  },
  watch: {
    elementId: function () {
      if (this.mode === 'edit') {
        this.updateModalContent()
      }
    },
    mode: function (newMode) {
      if (newMode === 'add') {
        this.initElementData()
      } else if (newMode === 'edit') {
        this.updateModalContent()
      }
    }
  },
  created () {
    this.$store.dispatch('getEmployees').then((response) => {
      this.employees = JSON.parse(response.data)
    })
    this.$store.dispatch('getOrgUnits').then((response) => {
      this.orgUnits = JSON.parse(response.data)
    })
  }
}
</script>
