<template>
  <div class="container">
    <div class="row py-5 justify-content-center">
      <div class="col-3 py-5 text-center">
        <svg id="navbar-logo" class="bi mb-2" width="70" height="70" fill="currentColor">
          <use xlink:href="@/assets/icons/icons.svg#nut-fill"/>
        </svg>
        <div class="form-floating mb-3">
          <input v-model="username" type="text" class="form-control" id="inputUsername" placeholder="Benutzername eingeben...">
          <label for="inputUsername">Benutzername</label>
        </div>
        <div class="form-floating mb-3">
          <input v-model="password" type="password" class="form-control" id="inputPassword" placeholder="Passwort eingeben...">
          <label for="inputPassword">Passwort</label>
        </div>
        <button @click="login" class="btn btn-primary w-100">Login</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    login () {
      this.$store.dispatch('login', { username: this.username, password: this.password })
    }
  }
}
</script>
