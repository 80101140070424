import { createStore } from 'vuex'
import { HTTP } from '../http-common'
import router from '../router'

export default createStore({
  state: {
    loginStatus: false,
    loadingStatus: false,
    alerts: [],
    username: '',
    selectedKPIs: [],
    objectivesContentView: 'detail',
    selectedObjective: {},
    parentObjectives: [],
    newObjective: {
      parentId: '',
      name: '',
      orgUnitId: '',
      inChargeId: '',
      ambitious: false,
      realistic: false,
      startDate: '',
      endDate: '',
      kpis: []
    }
  },
  mutations: {
    SET_LOGIN_STATUS (state) {
      state.loginStatus = true
    },
    SET_LOADING_STATUS (state, loadingStatus) {
      state.loadingStatus = loadingStatus
    },
    ADD_ALERT (state, alert) {
      state.alerts.push(alert)
    },
    DELETE_ALERT (state, index) {
      state.alerts.splice(index, 1)
    },
    DELETE_ALERTS (state) {
      state.alerts = []
    },
    SET_USERNAME (state, username) {
      state.username = username
    },
    SET_SELECTED_KPIS (state, selectedKPIs) {
      state.selectedKPIs = selectedKPIs
    },
    SET_OBJECTIVES_CONTENT_VIEW (state, objectivesContentView) {
      state.objectivesContentView = objectivesContentView
    },
    SET_SELECTED_OBJECTIVE (state, selectedObjective) {
      state.selectedObjective = selectedObjective
    },
    SET_PARENT_OBJECTIVES (state, parentObjectives) {
      state.parentObjectives = parentObjectives
    },
    SET_NEW_OBJECTIVE (state, newObjective) {
      state.newObjective = newObjective
    }
  },
  actions: {
    initialiseApp ({ commit }) {
      return new Promise(() => {
        HTTP.get('set-csrf')
        if (localStorage.getItem('focusLoginStatus')) {
          commit('SET_LOGIN_STATUS', localStorage.getItem('focusLoginStatus'))
          commit('SET_USERNAME', localStorage.getItem('focusUsername'))
        }
      })
    },
    login ({ commit }, payload) {
      return new Promise(() => {
        HTTP.post('login', payload)
          .then(response => {
            commit('SET_LOGIN_STATUS', true)
            commit('SET_USERNAME', response.data.username)
            localStorage.setItem('focusLoginStatus', true)
            localStorage.setItem('focusUsername', response.data.username)
            router.push('/objectives')
          }, () => {
            commit('ADD_ALERT', { category: 'Error', message: 'Es ist ein Fehler aufgetreten.' })
          })
      })
    },
    logout ({ commit }) {
      return new Promise(() => {
        HTTP.post('logout')
          .then(() => {
            commit('SET_LOGIN_STATUS', false)
            commit('SET_USERNAME', '')
            localStorage.removeItem('focusLoginStatus')
            localStorage.removeItem('focusUsername')
            location.reload()
          }, () => {
            commit('ADD_ALERT', { category: 'Error', message: 'Es ist ein Fehler aufgetreten.' })
          })
      })
    },
    getEmployees ({ commit }) {
      return new Promise((resolve) => {
        HTTP.get('employees')
          .then(response => {
            return resolve(response.data)
          }, () => {
            commit('ADD_ALERT', { category: 'Error', message: 'Es ist ein Fehler aufgetreten.' })
          })
      })
    },
    getOrgUnits ({ commit }) {
      return new Promise((resolve) => {
        HTTP.get('org-units')
          .then(response => {
            return resolve(response.data)
          }, () => {
            commit('ADD_ALERT', { category: 'Error', message: 'Es ist ein Fehler aufgetreten.' })
          })
      })
    },
    getKPIs ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.get('kpis?structure=' + payload.structure)
          .then(response => {
            return resolve(response.data)
          }, () => {
            commit('ADD_ALERT', { category: 'Error', message: 'Es ist ein Fehler aufgetreten.' })
          })
      })
    },
    getKPI ({ commit, state }, payload) {
      return new Promise((resolve) => {
        HTTP.get('kpis/' + payload.kpiId + '/?timedim=' + payload.filter.timedim + '&timefrom=' + payload.filter.timefrom + '&timeto=' + payload.filter.timeto)
          .then(response => {
            var selectedKPIs = state.selectedKPIs
            const newFilter = {
              timedim: 3,
              timefrom: 2021,
              timeto: 2025
            }
            if (!selectedKPIs.find(kpi => kpi.id === payload.kpiId)) {
              if (response.data.data === 'empty') {
                selectedKPIs.push({ id: payload.kpiId, name: payload.kpiName, periods: false, filter: newFilter })
              } else {
                var newKPI = JSON.parse(response.data.data)
                newKPI.filter = newFilter
                selectedKPIs.push(newKPI)
              }
            } else {
              const kpiIndex = selectedKPIs.findIndex(kpi => kpi.id === payload.kpiId)
              const kpiFilter = selectedKPIs[kpiIndex].filter
              if (response.data.data === 'empty') {
                selectedKPIs[kpiIndex] = { id: payload.kpiId, name: payload.kpiName, periods: false, filter: kpiFilter }
              } else {
                selectedKPIs[kpiIndex] = JSON.parse(response.data.data)
                selectedKPIs[kpiIndex].filter = kpiFilter
              }
            }
            commit('SET_SELECTED_KPIS', selectedKPIs)
            return resolve()
          }, () => {
            commit('ADD_ALERT', { category: 'Error', message: 'Es ist ein Fehler aufgetreten.' })
          })
      })
    },
    deleteSelectedKPI ({ commit, state }, payload) {
      var selectedKPIs = state.selectedKPIs
      selectedKPIs.splice(selectedKPIs.map(kpi => kpi.id).indexOf(payload.kpiId), 1)
      commit('SET_SELECTED_KPIS', selectedKPIs)
    },
    updateObjectivesContentView ({ commit }, payload) {
      commit('SET_OBJECTIVES_CONTENT_VIEW', payload.objectivesContentView)
    },
    addObjectiveKPI ({ commit, state }, payload) {
      var newObjective = state.newObjective
      if (!newObjective.kpis.find(kpi => kpi.id === payload.kpi.id)) {
        newObjective.kpis.push(payload.kpi)
        commit('SET_NEW_OBJECTIVE', newObjective)
      }
    },
    deleteObjectiveKPI ({ commit, state }, payload) {
      var newObjective = state.newObjective
      newObjective.kpis.splice(newObjective.kpis.map(kpi => kpi.id).indexOf(payload.kpiId), 1)
      commit('SET_NEW_OBJECTIVE', newObjective)
    },
    addObjective ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.post('objectives', payload.newObjective)
          .then(response => {
            return resolve(response.data)
          }, () => {
            commit('ADD_ALERT', { category: 'Error', message: 'Es ist ein Fehler aufgetreten.' })
          })
      })
    },
    getParentObjectives ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.get('parent-objectives?year=' + payload.objectiveYear)
          .then(response => {
            commit('SET_PARENT_OBJECTIVES', JSON.parse(response.data.data))
            return resolve()
          }, () => {
            commit('ADD_ALERT', { category: 'Error', message: 'Es ist ein Fehler aufgetreten.' })
          })
      })
    },
    getObjectives ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.get('objectives?view=' + payload.objectivesTreeView + '&year=' + payload.selectedYear)
          .then(response => {
            return resolve(response.data)
          }, () => {
            commit('ADD_ALERT', { category: 'Error', message: 'Es ist ein Fehler aufgetreten.' })
          })
      })
    },
    getObjective ({ commit, state }, payload) {
      return new Promise((resolve) => {
        HTTP.get('objectives/' + payload.objectiveId + '/')
          .then(response => {
            commit('SET_SELECTED_OBJECTIVE', JSON.parse(response.data.data))
            this.dispatch('getParentObjectives', { objectiveYear: state.selectedObjective.startDate.slice(-2) })
            return resolve()
          }, () => {
            commit('ADD_ALERT', { category: 'Error', message: 'Es ist ein Fehler aufgetreten.' })
          })
      })
    },
    updateObjective ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.post('objectives/' + payload.objective.id + '/', payload.objective)
          .then(response => {
            return resolve(response.data)
          }, () => {
            commit('ADD_ALERT', { category: 'Error', message: 'Es ist ein Fehler aufgetreten.' })
          })
      })
    },
    addObjectiveElement ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.post('objectives/' + payload.objectiveId + '/' + payload.elementType, payload.element)
          .then(response => {
            return resolve(response.data)
          }, () => {
            commit('ADD_ALERT', { category: 'Error', message: 'Es ist ein Fehler aufgetreten.' })
          })
      })
    },
    updateObjectiveElement ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.post('objectives/' + payload.objectiveId + '/' + payload.elementType + '/' + payload.element.id + '/', payload.element)
          .then(response => {
            return resolve(response.data)
          }, () => {
            commit('ADD_ALERT', { category: 'Error', message: 'Es ist ein Fehler aufgetreten.' })
          })
      })
    },
    deleteObjectiveElement ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.delete('objectives/' + payload.objectiveId + '/' + payload.elementType + '/' + payload.elementId + '/')
          .then(response => {
            return resolve(response.data)
          }, () => {
            commit('ADD_ALERT', { category: 'Error', message: 'Es ist ein Fehler aufgetreten.' })
          })
      })
    }
  }
})
