import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import Landing from '../views/Landing.vue'
import Login from '../views/Login.vue'
import KPIStructure from '../views/monitoring/KPIStructure.vue'
import Objectives from '../views/objectives/Objectives.vue'
import Projects from '../views/projects/Projects.vue'
import ManageOrgUnits from '../views/manage/OrgUnits'
import DemoXMatrix from '../views/demo/XMatrix'

function isAuthenticated (to, from, next) {
  store.state.loginStatus ? next() : next('/login')
}

function accessLogin (to, from, next) {
  store.state.loginStatus ? next('/objectives') : next()
}

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/login',
    name: 'Login',
    beforeEnter: accessLogin,
    component: Login
  },
  {
    path: '/kpi-structure',
    name: 'KPIStructure',
    beforeEnter: isAuthenticated,
    component: KPIStructure
  },
  {
    path: '/objectives',
    beforeEnter: isAuthenticated,
    name: 'Objectives',
    component: Objectives
  },
  {
    path: '/projects',
    name: 'Projects',
    beforeEnter: isAuthenticated,
    component: Projects
  },
  {
    path: '/manage/org-units',
    beforeEnter: isAuthenticated,
    name: 'ManageOrgUnits',
    component: ManageOrgUnits
  },
  {
    path: '/demo/x-matrix',
    beforeEnter: isAuthenticated,
    name: 'DemoXMatrix',
    component: DemoXMatrix
  },
  {
    path: '/:pathMatch(.*)*',
    beforeEnter: isAuthenticated,
    name: 'NotFound',
    redirect: { name: 'Objectives' }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
