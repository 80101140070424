<template>
  <div class="container-fluid">
    <div class="row">
      <div id="sidebar-manage-org-units" class="col-3 bg-custom-blue pb-5">
        <div class="row mt-3">
          <div class="col-12">
            <button type="button" class="btn btn-sm btn-outline-primary" data-bs-toggle="modal" data-bs-target="#orgUnitEditModal">+ Org-Einheit hinzufügen</button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div v-if="orgUnits.length" class="manage-org-units-tree mt-3">
              <div v-for="orgUnit in orgUnitsTree" @click="selectOrgUnit(orgUnit.id)" :key="orgUnit.id" class="label-wrapper py-2 border-bottom border-dark text-truncate">
                <div :style="getIndent(orgUnit.depth)">
                  <div class="icon-pre-text">
                    <svg class="bi" width="22" height="22" fill="currentColor">
                      <use xlink:href="@/assets/icons/icons.svg#org-unit"/>
                    </svg>
                    {{ orgUnit.label }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="text-center mt-5">
              <img src="@/assets/img/no_data.svg" class="img-fluid" width="120">
              <p class="h5 mt-3">Keine Org-Einheiten vorhanden.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-9 px-5">
        <div v-if="Object.keys(selectedOrgUnit).length" class="row">
          <div class="col-12">
            <div class="row mt-3">
              <div class="col">
                <button type="button" class="btn btn-sm btn-outline-dark">&bull; Org-Einheit bearbeiten</button>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <h3>{{ selectedOrgUnit.label }}</h3>
                <span class="fs-6">Struktur-Code: {{ selectedOrgUnit.structureCode }}</span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4">
                <div class="card mb-3">
                  <div class="card-header bg-primary text-light">Mission</div>
                  <div class="card-body">
                    <p class="card-text">{{ selectedOrgUnit.mission }}</p>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="card mb-3">
                  <div class="card-header bg-primary text-light">Vision</div>
                  <div class="card-body">
                    <p class="card-text">{{ selectedOrgUnit.vision }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">Org-Einheit-Details</div>
                  <div class="card-body">
                    <ul class="nav nav-tabs border-bottom" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button class="nav-link text-dark active" id="employees-tab" data-bs-toggle="tab" data-bs-target="#employees" type="button" role="tab" aria-controls="employees" aria-selected="false">Mitarbeiter <span class="badge bg-dark">1</span></button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link text-dark" id="posts-tab" data-bs-toggle="tab" data-bs-target="#posts" type="button" role="tab" aria-controls="posts" aria-selected="false">Planstellen <span class="badge bg-dark">2</span></button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link text-dark" id="fields-tab" data-bs-toggle="tab" data-bs-target="#fields" type="button" role="tab" aria-controls="fields" aria-selected="false">Zielfelder</button>
                      </li>
                    </ul>
                    <div class="tab-content mt-2">
                      <div class="tab-pane fade show active" id="employees" role="tabpanel">
                        <div class="row my-3">
                          <div class="col-6">
                            <button type="button" class="btn btn-sm btn-outline-primary">+ Hinzufügen</button>
                          </div>
                        </div>
                        <div>
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Mitarbeiter</th>
                                <th scope="col">Planstelle</th>
                                <th scope="col">Faktor</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">Peter Schmitz</th>
                                <td>Managing Director</td>
                                <td>100%</td>
                                <td>
                                  <div class="dropdown">
                                    <a href="#" class="ms-2 text-dark" data-bs-toggle="dropdown">
                                      <svg class="bi" width="28" height="28" fill="currentColor">
                                        <use xlink:href="@/assets/icons/icons.svg#three-dots"/>
                                      </svg>
                                    </a>
                                    <ul class="dropdown-menu">
                                      <li><a class="dropdown-item" href="#">Bearbeiten</a></li>
                                      <li><a class="dropdown-item" href="#">Löschen</a></li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="posts" role="tabpanel">
                        <div class="row my-3">
                          <div class="col-6">
                            <button type="button" class="btn btn-sm btn-outline-primary">+ Hinzufügen</button>
                          </div>
                        </div>
                        <div>
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Planstelle</th>
                                <th scope="col">Leitung</th>
                                <th scope="col">Datum</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">Managing Director</th>
                                <td>
                                  <svg class="bi" width="28" height="28" fill="currentColor">
                                    <use xlink:href="@/assets/icons/icons.svg#check"/>
                                  </svg>
                                </td>
                                <td>10.06.22</td>
                                <td>
                                  <div class="dropdown">
                                    <a href="#" class="ms-2 text-dark" data-bs-toggle="dropdown">
                                      <svg class="bi" width="28" height="28" fill="currentColor">
                                        <use xlink:href="@/assets/icons/icons.svg#three-dots"/>
                                      </svg>
                                    </a>
                                    <ul class="dropdown-menu">
                                      <li><a class="dropdown-item" href="#">Bearbeiten</a></li>
                                      <li><a class="dropdown-item" href="#">Löschen</a></li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Sales and Marketing Director</th>
                                <td>
                                  <svg class="bi" width="28" height="28" fill="currentColor">
                                    <use xlink:href="@/assets/icons/icons.svg#check"/>
                                  </svg>
                                </td>
                                <td>05.11.22</td>
                                <td>
                                  <div class="dropdown">
                                    <a href="#" class="ms-2 text-dark" data-bs-toggle="dropdown">
                                      <svg class="bi" width="28" height="28" fill="currentColor">
                                        <use xlink:href="@/assets/icons/icons.svg#three-dots"/>
                                      </svg>
                                    </a>
                                    <ul class="dropdown-menu">
                                      <li><a class="dropdown-item" href="#">Bearbeiten</a></li>
                                      <li><a class="dropdown-item" href="#">Löschen</a></li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="fields" role="tabpanel">
                        <div class="row my-3">
                          <div class="col-6">
                            <button type="button" class="btn btn-sm btn-outline-primary">+ Hinzufügen</button>
                          </div>
                        </div>
                        <div>
                          <h5>Diese Org-Einheit beinhaltet keine Zielfelder.</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="text-center mt-5">
          <img src="@/assets/img/no_data.svg" class="img-fluid" width="250">
          <p class="h5 mt-3">Erstellen Sie über den oberen Button Ihre erste Org-Einheit.</p>
        </div>
        <div class="modal fade" id="orgUnitEditModal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content bg-primary">
              <div class="modal-header">
                <h5 class="modal-title text-light">Org-Einheit hinzufügen</h5>
                <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body bg-light">
                <div class="form-org-unit">
                  <div class="mb-3">
                    <label for="parent" class="form-label">Übergeordnete Org-Einheit</label>
                    <select v-model="modalOrgUnit.parentId" class="form-select">
                      <option value="0">(Keine)</option>
                      <option v-for="orgUnit in orgUnitsTree" :key="orgUnit.id" :value="orgUnit.id">
                        <span>{{ getOrgUnitDashes(orgUnit.depth) }}</span>
                        {{ orgUnit.label }}
                      </option>
                    </select>
                  </div>
                  <div class="mb-3">
                    <label for="label" class="form-label">Name</label>
                    <input v-model="modalOrgUnit.label" class="form-control">
                  </div>
                  <div class="mb-3">
                    <label for="mission" class="form-label">Mission</label>
                    <textarea v-model="modalOrgUnit.mission" class="form-control" rows="3"></textarea>
                  </div>
                  <div class="mb-3">
                    <label for="vision" class="form-label">Vision</label>
                    <textarea v-model="modalOrgUnit.vision" class="form-control" rows="3"></textarea>
                  </div>
                </div>
              </div>
              <div class="modal-footer bg-light justify-content-between">
                <div>
                  <button type="button" class="btn btn-sm btn-outline-danger disabled">Löschen</button>
                </div>
                <div>
                  <button type="button" class="btn btn-sm btn-outline-secondary me-2" data-bs-dismiss="modal">Schließen</button>
                  <button @click="saveOrgUnit()" type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal">Speichern</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ManageOrgUnits',
  data () {
    return {
      orgUnits: [],
      selectedOrgUnit: {},
      modalOrgUnit: { parentId: 0 },
      currentId: 100
    }
  },
  computed: {
    depth () {
      if (this.orgUnits.length) {
        return Math.max.apply(Math, this.orgUnits.map(function (org) { return org.depth }))
      } else {
        return 1
      }
    },
    orgUnitsTree () {
      return this.orgUnits.slice().sort(function (a, b) {
        if (a.structureCode < b.structureCode) return -1
        if (a.structureCode > b.structureCode) return 1
        return 0
      })
    }
  },
  methods: {
    saveOrgUnit () {
      if (this.modalOrgUnit.label && this.modalOrgUnit.mission && this.modalOrgUnit.vision) {
        this.modalOrgUnit.id = this.currentId
        if (this.modalOrgUnit.parentId === 0) {
          this.modalOrgUnit.depth = 1
          this.modalOrgUnit.structureCode = '1.' + (this.orgUnits.length + 1)
        } else {
          const parentOrgUnit = this.orgUnits.find(org => org.id === this.modalOrgUnit.parentId)
          this.modalOrgUnit.depth = parentOrgUnit.depth + 1
          const parentCounter = this.orgUnits.filter((org) => org.parentId === parentOrgUnit.id).length
          this.modalOrgUnit.structureCode = parentOrgUnit.structureCode + '.' + (parentCounter + 1)
        }
        this.orgUnits.push(this.modalOrgUnit)
        this.selectedOrgUnit = this.modalOrgUnit
        this.modalOrgUnit = { parentId: 0 }
        this.currentId += 1
      }
    },
    selectOrgUnit (id) {
      this.selectedOrgUnit = this.orgUnits.find(org => org.id === id)
    },
    getIndent (depth) {
      return { transform: `translate(${(depth - 1) * 22}px)` }
    },
    getOrgUnitDashes (depth) {
      var dashes = '-'
      var i = 1
      while (i < depth) {
        dashes = dashes + ' -'
        i++
      }
      return dashes
    }
  }
}
</script>

<style lang="scss" scoped>
.manage-org-units-tree {
  .label-wrapper svg {
    margin-top: -2px;
    margin-left: 2px;
  }
  .label-wrapper {
    cursor: pointer;
  }
  .label-wrapper:hover {
    background-color: #212529;
    color: #f8f9fa;
  }
  .icon-pre-text {
    width: 22px;
    display: inline-block;
  }
}
</style>
